import React from 'react';

import SidebarMenu from '../../../../components/SidebarMenu';
const UserDetailsSidebar = ({ user_id, username, scope }) => {
  const menuItems = [
    {
      title: 'Purchases',
      url: `/users/${user_id}/${username}/purchases`
    },
    {
      title: 'Buying Swaps',
      url: `/users/${user_id}/${username}/swaps`
    },
    {
      title: 'Favorites',
      url: `/users/${user_id}/${username}/favorites`
    }
    // {
    //   title: 'Swaps',
    //   url: `/sellers/${user_id}/${username}/swaps`
    //   //url: `/swaps/${username}`
    // }
  ];
  return (
    <SidebarMenu menuItems={menuItems} />
    // <List>
    //   <NavLink to={`/users/${user_id}/${username}/purchases`}>
    //     <ListItem>
    //       <ListItemText primary='Purchases' />
    //     </ListItem>
    //   </NavLink>
    //   <NavLink to={`/users/${user_id}/${username}/negotiations`}>
    //     <ListItem>
    //       <ListItemText primary='Negotiations' />
    //     </ListItem>
    //   </NavLink>
    //   <NavLink to={`/users/${user_id}/${username}/favorites`}>
    //     <ListItem>
    //       <ListItemText primary='Favorites' />
    //     </ListItem>
    //   </NavLink>
    // </List>
  );
};

export default UserDetailsSidebar;
