import React, { useState, useEffect } from 'react';
// import TagsInput from 'react-tagsinput';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DraggableArea } from 'react-draggable-tags';
import ClearIcon from '@material-ui/icons/Clear';
//import 'react-tagsinput/react-tagsinput.css';


const CategoryAttributeItem = ({ index, attribute, onChange, onRemove }) => {

  const [newTag, setNewTag] = useState('');
  //listOfTags is the array that the DND library will be using to update the items on the browser
  const [listOfTags, setListOfTags] = useState([]);

  useEffect(() => {
    // Creating a new array from the given attribute.options array because the dnd library only uses array of objects where
    // every item needs an unique id
    const newArrayOfOptions = attribute.options ? attribute.options.map((item, index) => ({ id: item, content: item })) : [];
    setListOfTags(newArrayOfOptions);
    console.log(newArrayOfOptions)
  }, [attribute.options])


  const onInputChange = (key, value) => {
    onChange({ ...attribute, [key]: value });
  };

  const onTagsChange = (tags) => {
    setListOfTags(tags);
    onInputChange('options', tags.map(tag => tag.content), tags)
  }

  const handleClickAdd = (e) => {
    e.preventDefault();
    if (newTag) {
      console.log(listOfTags)
      const tempTags = listOfTags.concat({ id: newTag, content: newTag })
      onTagsChange(tempTags)
      setNewTag("");

    }
  }

  const handleClickDelete = (tag) => {
    const tags = listOfTags.filter(t => tag.id !== t.id);
    onInputChange('options', tags.map(tag => tag.content), tags)
  }

  return (
    <div className='form-row'>
      <div className='form-group col-2'>
        <label>Name</label>
        <input
          type='text'
          name={`name[${index}]`}
          onChange={e => onInputChange('name', e.target.value)}
          value={attribute.name || ''}
          className='form-control'
        />
      </div>
      <div className='form-group col-2'>
        <label>Slug</label>
        <input
          type='text'
          name={`slug[${index}]`}
          onChange={e => onInputChange('slug', e.target.value)}
          value={attribute.slug || ''}
          className='form-control'
        />
      </div>
      <div className='form-group col-6'>
        <label>Options</label>
        <div className="AddAndDelete">
          <div className="main">
            <DraggableArea
              tags={listOfTags}
              render={({ tag, index }) => (
                <div className="tag" key={index}>
                  <div className="delete"
                    onClick={() => handleClickDelete(tag)}>
                    <ClearIcon style={{ fontSize: "14px", color: "#fff" }} />
                  </div>
                  {tag.content}
                </div>
              )}
              // Transforming the modified array of objects back to a regular array of items and passing to the onInputChange function
              onChange={onTagsChange}
            />
          </div>
          <form>
            <div className="inputs float-right">
              <input value={newTag} onChange={e => setNewTag(e.target.value)} placeholder="add tag" />
              <button type="submit" className='btn btn-outline-secondary ml-2' onClick={e => handleClickAdd(e)}>Add</button>
            </div>
          </form>
        </div>
      </div>
      <div className='form-group col-2'>
        <IconButton onClick={onRemove}>
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};

export default CategoryAttributeItem;
