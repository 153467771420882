import React from 'react';
// import logo from './logo.svg';
import './App.css';
import './styles/custom.scss';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import {
//   blue700,
//   cyan700,
//   pinkA200,
//   grey100,
//   grey300,
//   grey400,
//   white,
//   darkBlack,
//   fullBlack
// } from '@material-ui/core/styles';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createMuiTheme } from '@material-ui/core';
import { blue, cyan, grey, pink } from '@material-ui/core/colors';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Login from './modules/login/Login';
import Dashboard from './modules/dashboard/Dashboard';
import NotFound from './modules/NotFound';
import Shared from './components/shared/Shared';
import store from './redux/store';
import UserList from './modules/users/list/UserList';
// import ItemList from './modules/items/list/ItemList';
import UserCreateEdit from './modules/users/edit/UserCreateEdit';
import ViewContainer from './modules/users/view/ViewContainer';
import SellerDetailsContainer from './modules/sellers/view/SellerDetailsContainer';
import Toasts from './components/toasts/Toasts';
import Modals from './components/modals/Modals';
// import Swaps from './modules/swaps/list/Swaps';
// import SellersListContainer from './modules/sellers/list/SellersListContainer';
import CategoriesList from './modules/categories/list/CategoriesList';
import CategoryEdit from './modules/categories/edit/CategoryEdit';
import TransactionsList from './modules/transactions/list/TransactionsList';
import Swap from './modules/swaps/edit/Swap';
import ItemsList from './modules/items/list/components/newItemList';
import SwapsList from './modules/swaps/list/SwapsList';
import SellersList from './modules/sellers/list/SellersList';

const muiTheme = createMuiTheme({
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color: blue[700],
    primary2Color: cyan[700],
    primary3Color: grey[400],
    accent1Color: pink[400],
    accent2Color: grey[400],
    accent3Color: blue[700],
    textColor: blue[900],
    alternateTextColor: '#fff',
    canvasColor: '#fff',
    borderColor: grey[300],
    pickerHeaderColor: blue[700],
    shadowColor: '#000',
  },
  appBar: {},
});
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={muiTheme}>
          <Route exact path="/login" component={Login} />
          <Shared>
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route
                path="/dashboard/transactions"
                exact
                component={Dashboard}
              />
              <Route path="/dashboard/items" exact component={Dashboard} />

              <Route
                path="/users/:scope(create|edit)/:user_id?"
                exact
                component={UserCreateEdit}
              />
              <Route
                path="/users/:user_id/:username/:scope(purchases|addresses|payment-methods|favorites|swaps|negotiations)?"
                component={ViewContainer}
                exact
              />
              <Route path="/users" exact component={UserList} />
              <Route
                path="/sellers/:user_id/:username/:scope?"
                component={SellerDetailsContainer}
                exact
              />
              {/* <Route path='/sellers' component={SellersListContainer} /> */}
              {/* <Route path='/items' exact component={ItemList} /> */}
              <Route path="/items" exact component={ItemsList} />

              <Route path="/swaps/:swap_id" exact component={Swap} />

              {/* <Route path='/sellers' component={SellersListContainer} /> */}
              <Route path="/sellers" component={SellersList} />
              {/* <Route path='/items' exact component={ItemList} /> */}
              <Route path="/swaps/:swap_id" exact component={Swap} />
              {/* <Route path='/swaps' component={Swaps} exact /> */}
              <Route path="/swaps" component={SwapsList} exact />
              <Route
                path="/categories/:category_id"
                exact
                component={CategoryEdit}
              />

              <Route path="/categories" exact component={CategoriesList} />
              <Route path="/transactions" exact component={TransactionsList} />
              {/* <Route path="/logout" component={Logout} /> */}
              {/* <Route path="/items" exact component={Products} />
              <Route
                path="/items/categories"
                exact
                component={ProductCategories}
              />
              <Route path="/swaps" exact component={Orders} />
              
              <Route
                path="/swaps/:swapsId"
                exact
                component={OrderDetails}
              />
              <Route path="/users" exact component={Customers} />
              
              <Route
                path="/users/:userId"
                exact
                component={CustomerDetails}
              />
              <Route
                path="/items/:productId"
                component={ProductDetails}
              /> */}
              {/* <Route path="/admin/pages" exact component={Pages} />
              <Route path="/admin/pages/add" exact component={PagesDetails} />
              <Route path="/admin/pages/:pageId" component={PagesDetails} />
              <Route path="/admin/settings" component={Settings} />
              <Route path="/admin/apps" component={Apps} />
              <Route path="/admin/files" exact component={Files} /> */}
              <Route component={NotFound} />
            </Switch>
          </Shared>
          <Modals />
          <Toasts />
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    user: { isUserLoggedIn },
  } = { ...rest };
  return (
    <Route
      {...rest}
      render={(props) =>
        !isUserLoggedIn ? (
          <Redirect
            to={{
              pathname: '/login-register',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
const mapStateToProps = (state) => ({ user: state.user });
connect(mapStateToProps, null)(PrivateRoute);
