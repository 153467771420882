import React from 'react';
import { Container } from '@material-ui/core';
import CategoriesListTable from '../component/CategoriesListTable';
import { Grid } from '@material-ui/core';

const CategoriesList = ({ history }) => {
  return (
    <Container>
      <Grid container justify='flex-end'>
        <Grid item xs={3} className='createUpdateCategory'>
          <button
            className='btn btn-primary '
            onClick={() => history.push('/categories/create')}
          >
            Create New Category
          </button>
        </Grid>
      </Grid>
      <CategoriesListTable />
    </Container>
  );
};

export default CategoriesList;
