import React from 'react';
import UserForm from './components/UserForm';
import { User } from '../../../models/user';
import { withRouter } from 'react-router-dom';
import apiService from '../../../lib/apiService';
import { API_ENDPOINT_USERS } from '../../../lib/apiEndpoints';
import { connect } from 'react-redux';
import { showErrorToast } from '../../../redux/actions/toastsAction';

class UserCreateEdit extends React.PureComponent {
  state = {
    user: new User(),
    loading: false
  };
  componentDidMount() {
    const { scope } = this.props.match.params;
    if (scope !== 'create') {
      this.fetchUser();
    }
  }
  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };
  fetchUser = () => {
    this.toggleLoading(true);
    apiService
      .request(
        'GET',
        API_ENDPOINT_USERS + '/' + this.props.match.params.user_id
      )
      .then(resp => {
        this.setState({ user: { ...resp.data.user } });
      })
      .finally(() => this.toggleLoading());
  };
  onInputChange = e => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value }
    });
  };
  onSubmit = () => {
    const { user } = this.state;

    // if (!user.password) {

    //   this.props.showErrorToast('Password Is Required Feild');
    //   return;
    // }
    const isNewUser = user._id ? false : true;
    apiService
      .request(
        isNewUser ? 'POST' : 'PUT',
        `users${isNewUser ? '' : `/${user._id}`}`,
        user
      )
      .then(() => {
        // this.props.history.push(`users/${user._id}/${user.username}`);
        this.props.history.push('/users');
      });
  };
  render() {
    return (
      <>
        <h3>{this.state.user._id ? 'Edit' : 'Create'} User</h3>
        <UserForm
          user={this.state.user}
          onInputChange={this.onInputChange}
          onSubmit={this.onSubmit}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    null,
    { showErrorToast }
  )(UserCreateEdit)
);
