import React from 'react';

import TransactionItem from './TransactionItem';
import { compose } from 'redux';
import { userTransactionsApiParams } from '../../lib/apiEndpoints';
import ListHOC from '../ListHoc';

const TransactionsHOC = WrappedComponent => {
  const Container = props => {
    const { type, user_id } = props;
    return <WrappedComponent {...props} apiParams={userTransactionsApiParams(type, user_id)} />;
  };
  return Container;
};
const Transactions = compose(
  TransactionsHOC,
  ListHOC({ defaultMessage: 'No transactions Found', type: 'list' })
)(TransactionItem);
export default Transactions;
