import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  // Delete as DeleteIcon,
  RemoveRedEye as ViewIcon
} from '@material-ui/icons';
import { Avatar, IconButton, Grid } from '@material-ui/core';

// import apiService from '../../../lib/apiService';

// import { API_ENDPOINT_SWAPS } from '../../../lib/apiEndpoints';

import { formatDate } from '../../../lib/helper';
import NewTableList from '../../../components/NewTableList';
import ListData from '../../../components/ListData';

// import { debounce } from '../../../lib/helper';
import { swapStates } from '../../../models/misc';
const onFilterChange = value => {
  console.log('values coming from tableList to swaplist is ', value);
};
const SwapsTable = withRouter(
  ({
    history,
    fetchData,
    data,
    params = {},
    onFilterChange,
    filterData,
    sorting,
    sortChange,
    ...rest
  }) => {
    // const swapTableRef = useRef();

    // const onSwapDeleteClicked = id => {
    //   apiService.request('Delete', API_ENDPOINT_SWAPS + '/' + id).then(() => {
    //     swapTableRef.current.fetchData();
    //     // this.forceUpdate();
    //   });
    // };

    const columns = [
      {
        name: '_id',
        label: 'Swap #',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData._id ? [filterData._id] : ''
        }
      },
      {
        name: 'item',
        label: 'Item',
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => {
            return (
              <>
                <div>
                  <div>
                    <Link to={`/items`}>
                      <Avatar src={value.images[0]['thumbnail']} />
                    </Link>
                  </div>
                  <div>
                    <Link to={`/items`}> {value.name}</Link>
                  </div>
                </div>
              </>
            );
          }
        }
      },
      {
        name: 'buyer',
        label: 'Buyer',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData.buyer ? [filterData.buyer] : '',
          customBodyRender: value => {
            if (!value) {
              return;
            }
            return (
              <Link to={`/users/${value._id}/${value.username}`}>
                {value.username}
              </Link>
            );
          }
        }
      },
      {
        name: 'seller',
        label: 'Seller',
        options: {
          filter: true,
          sort: false,
          filterType: 'textField',
          filterList: filterData.seller ? [filterData.seller] : '',
          customBodyRender: value => {
            if (!value) {
              return;
            }
            return (
              <Link to={`/sellers/${value._id}/${value.username}`}>
                {value.username}
              </Link>
            );
          }
        }
      },

      {
        name: 'state',
        label: 'State',
        options: {
          filter: true,
          filterList: filterData.state ? [filterData.state] : '',
          filterOptions: swapStates,
          customBodyRender: value => {
            return swapStates[value];
          }
        }
      },
      {
        name: 'created_at',
        label: 'Created At',
        options: {
          filter: false,
          filterType: 'textField',
          // filterOptions: {
          //   names: ['Available', 'Business Analyst']
          // },
          // filterOptions: { display: 'sdfeves' },
          filterList: filterData.created_at ? [filterData.created_at] : '',
          customBodyRender: value => {
            return formatDate(value);
          }
        }
      },
      {
        name: '_id',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <Fragment>
                <IconButton onClick={() => history.push(`/swaps/${value}`)}>
                  <ViewIcon />
                </IconButton>
                {/* <IconButton onClick={() => onSwapDeleteClicked(value)}>
                <DeleteIcon />
              </IconButton> */}
              </Fragment>
            );
          }
        }
      }
    ];

    columns.forEach(column => {
      if (column.name === sorting.column_name) {
        let sortDirection = sorting.direction ? sorting.direction : 'none';
        let tempCol = { ...column, sortDirection: sortDirection };
        column.options.options = { ...tempCol };
        // console.log(column);
      }
    });

    const handleFilterChange = filterList => {
      let list = filterList.map(filterData => {
        if (filterData.name !== 'state') {
          return filterData;
        }
        if (filterData.value.length > 0) {
          const state = Object.keys(swapStates).find(
            key => swapStates[key] === filterData.value[0]
          );
          if (state) {
            return { name: 'state', value: [state] };
          }
          return filterData;
        }
        return filterData;
      });
      onFilterChange(list);
    };
    return (
      <NewTableList
        columns={columns}
        title='Swaps'
        fetchData={fetchData}
        data={data}
        {...rest}
        filterData
        sortChange={sortChange}
        onFilterChange={handleFilterChange}
      />
    );
  }
);

const SwapList = ({ history }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <ListData
            endpoint='swaps'
            render={value => <SwapsTable {...value} />}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

SwapsTable.propTypes = {
  params: PropTypes.object
};
export default withRouter(SwapList);

// export default withRouter(SwapsTable);
