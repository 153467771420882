import React, { Fragment } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, BarChart, Bar } from "recharts";


const DashboardItems = ({ itemsData, category, items }) => {

    return (
        <BarChart
            width={1250}
            height={600}
            data={itemsData}
            margin={{
                top: 50,
                right: 0,
                left: -40,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={category} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={items} fill="#fc766a" />
        </BarChart>
    );
}

export default DashboardItems;