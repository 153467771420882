import React from 'react';
import {makeStyles,createStyles} from '@material-ui/styles';
import {CssBaseline} from '@material-ui/core';
import Head from '../head/Head';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';



const useStyles=makeStyles(theme =>
	createStyles({
		root: {
			display: 'flex'
		},

		content: {
			flexGrow: 1,
			padding: theme.spacing(3)
		},
		toolbar: theme.mixins.toolbar
	})
);
const Shared=props => {
	const {
		user: {isUserLoggedIn}
	}=props;
	console.log(props)
	const classes=useStyles();
	if(!isUserLoggedIn) {
		return <Redirect to='/login' />;
	}
	return (
		<div className={classes.root}>

			<CssBaseline />
			<Head />

			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
};
const mapStateToProps=state => {
	return {user: state.user};
};
export default connect(
	mapStateToProps,
	null
)(Shared);
