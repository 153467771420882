import React from 'react';
import ItemListTable from '../../items/list/components/ItemListTable';
import { withRouter } from 'react-router-dom';
import { userFavoritesApiParams } from '../../../lib/apiEndpoints';
const Favorites = props => {
  const { username } = props.match.params;
  return <ItemListTable {...userFavoritesApiParams(username)} />;
};

export default withRouter(Favorites);
