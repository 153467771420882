import React, { Fragment, useState,useContext } from 'react';
import {
  // Divider,
  // MenuItem,
  Drawer,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  ListItemText,
  Collapse
} from '@material-ui/core';


import {
  Home as HomeIcon,
  Person as PersonIcon,
  LocalOffer as LocalOfferIcon,
  ShoppingCart as ShoppingCartIcon,
  Category as CategoryIcon,
  // Money as MoneyIcon,
  FileCopy
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
const menuItems = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: <HomeIcon />
  },
  {
    title: 'Users',
    url: '/users',
    icon: <PersonIcon />,
    children: [{ title: 'Sellers', url: '/sellers' }]
  },
  {
    title: 'Items',
    url: '/items',
    icon: <LocalOfferIcon />
  },
  {
    title: 'Item Categories',
    url: '/categories',
    icon: <CategoryIcon />
  },
  {
    title: 'Swaps',
    url: '/swaps',
    icon: <FileCopy />
  },
  {
    title: 'Transactions',
    url: '/transactions',
    icon: <ShoppingCartIcon />
  }
  // {
  //   title: 'Transactions',
  //   url: '/transactions',
  //   icon: <MoneyIcon />
  // }
  // {
  //     title: messages.drawer_orders,
  //     url: '/admin/orders',
  //     icon: 'shopping_cart'
  // },
  // {
  //     title: messages.drawer_customers,
  //     url: '/admin/customers',
  //     icon: 'person'
  // },
  // {
  //     title: messages.settings_pages,
  //     url: '/admin/pages',
  //     icon: 'description'
  // },
  // {
  //     title: messages.files,
  //     url: '/admin/files',
  //     icon: 'folder'
  // },
  // {
  //     title: '-',
  //     url: 'settings'
  // },
  // {
  //     title: messages.drawer_settings,
  //     url: '/admin/settings',
  //     icon: 'settings'
  // },
  // {
  //     title: messages.apps,
  //     url: '/admin/apps',
  //     icon: 'apps'
  // },
  // {
  //     title: messages.drawer_logout,
  //     url: '/admin/logout',
  //     icon: 'exit_to_app'
  // }
];

const drawerWidth = 240;
const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    list: {
      paddingTop: '80px'
    },
    link: {
      display: 'block',
      color: 'rgba(0,0,0,0.82)',
      textDecoration: 'none'
    },
    linkActive: {
      color: 'rgb(25, 118, 210)'
      // backgroundColor: 'rgba(0,0,0,0.05)'
    },
    icon: {
      left: 12,
      color: 'rgba(0,0,0,0.54)'
    },
    iconActive: {
      left: 12,
      color: 'inherit'
    }
  })
);
const MenuLink = ({ currentUrl, link }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const classes = useStyles();
  return (
    <Fragment>
      <ListItem
        button
        component={link.url && Link}
        to={link.url}
        onClick={toggleCollapse}
        className={currentUrl.includes(link.url) ? classes.linkActive : ''}
      >
        {link.icon && (
          <ListItemIcon
            className={currentUrl.includes(link.url) ? classes.linkActive : ''}
          >
            {link.icon}
          </ListItemIcon>
        )}
        <ListItemText primary={link.title} />
      </ListItem>
      {link.children && (
        <Collapse in={currentUrl.includes(link.url) || isOpen}>
          <List>
            {link.children.map((childLink, index) => (
              <MenuLink key={index} currentUrl={currentUrl} link={childLink} />
            ))}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
};
const DrawerMenu=({open,onClose,currentUrl}) => {
	 
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      {/* <div className={classes.toolbar} /> */}
      <List className={classes.list}>
        {menuItems.map((link, index) => (
          <MenuLink key={index} currentUrl={currentUrl} link={link} />
        ))}
      </List>
    </Drawer>
  );
};

export default DrawerMenu;
