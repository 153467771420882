import React from 'react'
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import apiService from '../../../../lib/apiService';
// import {
//   swapsEndpoint2 
// } from '../../../../lib/apiEndpoints';
export default function FooterLayout({addNote}) {

	const noteInputRef=React.createRef();
	const onSendBtnClicked=() => {
		let {
			value
		}=noteInputRef.current;
		if(!value.trim()) {
			return;
		}
		// logic to scan message and then send message

		if(value) {
			const emailRegex=/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-])/g;
			const phoneRegex=/([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})/g;
			value=value.replace(emailRegex,'***************');
			value=value.replace(phoneRegex,'***************');
			addNote(value.trim());
			console.log(value)


			noteInputRef.current.value='';
		}

	};

	const handleKeyDown=(e) => {
		e.target.style.height='inherit';
		e.target.style.height=`${e.target.scrollHeight}px`;
		if(e.key==='Enter') {
			e.preventDefault();
			onSendBtnClicked();
		}

	};


// note-footer-outerDiv
	return (
		<div>
			<label>Add Note <ContactSupportIcon /></label>
			<div className="row mt-4 align-items-center">
				<div className="col-lg-9">
					<textarea
						placeholder="Note.."
						type="text"
						ref={noteInputRef}
						className="form-control note-input" 
						id="text"
						onKeyDown={handleKeyDown}
						tabIndex="0"
						row="1"

					//float-left onChange={handleResize} style={{height: "132px"}}
					/>
				</div>
				<div className="col-lg-3">
					<button
						type="button"
						className="btn btn-outline-primary btn-block"

						onClick={onSendBtnClicked}
					>
						Add
       				 </button>
				</div>
			</div>
		</div>
	);
}
