import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../lib/helper';
import { Grid, CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// import { swapStates } from '../models/misc';

const NewTableList = ({
  paginate,

  onFilterChange = () => {},
  data,
  onSearchChange,
  searchTerm,
  meta,
  paging,
  filter,
  isLoading,
  sortChange,
  columns,
  title
}) => {
  const onFilterChangeWithDelay = debounce(onFilterChange, 2000);

  const onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        paginate({
          page: tableState.page + 1,
          page_size: tableState.rowsPerPage
        });
        break;
      case 'changeRowsPerPage':
        paginate({
          page: 1,
          page_size: tableState.rowsPerPage
        });
        break;
        // const onSortChange = () => {
        //   console.log('Soting##################################');
        // };
        const filterChange = () => {};
      // const options = {
      //   //filter: this.props.filter,
      //   responsive: 'stacked',
      //   serverSide: true,
      //   count: meta.paging.total_count,
      //   rowsPerPage: meta.paging.page_size,
      //   //page: paging.page,
      //   onTableChange: onTableChange,
      //   print: false,
      //   download: false,
      //   onFilterChange: filterChange,
      //   // onColumnSortChange: onSortChange,
      //   viewColumns: false,
      //   search: true,
      //   searchText: searchTerm,
      //   onSearchChange: debounce(onSearchChange, 2000),
      //   onSearchClose: onSearchChange,
      //   filter: false,
      //   selectableRows: 'none'
      //   // isRowSelectable:()=>{return false}
      // };

      case 'filterChange':
        let filterList = tableState.columns.map((column, key) => {
          return {
            // column,
            name: column.name,
            value: tableState.filterList[key] ? tableState.filterList[key] : []
          };
        });

        onFilterChangeWithDelay && onFilterChangeWithDelay(filterList);

        break;

      case 'sort':
        let temp = tableState.announceText.split(':');
        let sortDirection = temp[1];

        let sortBy = temp[0].split('by');
        let columnName = sortBy[1];
        // console.log('inside new table list ', sortDirection, columnName);
        sortChange(sortDirection === 'descending' ? 'desc' : 'asc', columnName);

        break;
      default:
        break;
    }
  };

  // const onSortChange = () => {};
  // const filterChange = () => {
  //   console.log(data, 'last data');
  // };
  const options = {
    //filter: this.props.filter,
    responsive: 'stacked',
    serverSide: true,
    count: meta.paging.total_count,
    rowsPerPage: meta.paging.page_size,
    // page: paging.page,
    onTableChange: onTableChange,
    print: false,
    download: false,
    // onFilterChange: onFilterChange,
    // onColumnSortChange: onSortChange,
    viewColumns: false,
    search: true,
    searchText: searchTerm,
    onSearchChange: debounce(onSearchChange, 2000),
    onSearchClose: onSearchChange,
    filter: filter,
    selectableRows: 'none'
    // isRowSelectable:()=>{return false}
  };

  //const { columns, title } = this.props;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MUIDataTable
            title={
              <div>
                {title && title}
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </div>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};
NewTableList.prototypes = {
  fetchData: PropTypes.func.isRequired,
  paginate: PropTypes.func,
  data: PropTypes.array,
  meta: PropTypes.object,
  paging: PropTypes.shape({
    page: PropTypes.number,
    page_size: PropTypes.number
  }),
  columns: PropTypes.array.isRequired,
  filter: PropTypes.bool,
  filterList: PropTypes.node,
  isLoading: PropTypes.bool
};
NewTableList.defaultProps = {
  meta: {
    paging: {
      page: 1,
      page_size: 10,
      total_count: 0
    }
  },
  paging: {
    page: 1,
    page_size: 10
  }
};
export default NewTableList;
