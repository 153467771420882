export default class ModalParams {
  id?: number;
  type?: 'confirmation' | 'custom';
  title?: string;
  confirmationText?: 'string';
  size?: 'large' | 'medium';
  showCloseBtn?: false;
  onClose?: () => {};
  onConfirm?: () => {};
  content?: any;
  props?: any;
}
