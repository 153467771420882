import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as auth from './lib/auth';

auth.validateCurrentToken();
ReactDOM.render(<App />,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// REACT_APP_FRONTEND_URL = http://localhost:3000
// REACT_APP_API_URL = //api.pcswaps.com/
// REACT_APP_FRONTEND_URL = https://www.pcswaps.com