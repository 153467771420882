/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/** https://scotch.io/tutorials/build-custom-pagination-with-react */

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};
class Pagination extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      total_count = null,
      page_size = 30,
      pageNeighbours = 0,
      page = 1
    } = props;

    this.page_size = typeof page_size === 'number' ? page_size : 30;
    this.total_count = typeof total_count === 'number' ? total_count : 0;

    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.total_count / this.page_size);

    this.state = {
      page: page
    };
  }

  componentDidMount() {
    //this.gotoPage(1);
  }

  gotoPage = setPage => {
    const { onPageChanged = f => f } = this.props;

    const page = Math.max(0, Math.min(setPage, this.totalPages));

    const paginationData = {
      page,
      totalPages: this.totalPages,
      page_size: this.page_size,
      total_count: this.total_count
    };

    this.setState({ page }, () => onPageChanged(paginationData));
  };

  handleClick = page => evt => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.page - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.page + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const page = this.state.page;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, page - pageNeighbours);
      const endPage = Math.min(totalPages - 1, page + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };
  render() {
    if (!this.total_count || this.totalPages === 1) return null;

    const { page } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <nav aria-label='Pagination' className='mt-4'>
          <ul className='pagination'>
            {pages.map((pageItem, index) => {
              if (pageItem === LEFT_PAGE)
                return (
                  <li key={index} className='page-item'>
                    <a
                      className='page-link'
                      href='#'
                      aria-label='Previous'
                      onClick={this.handleMoveLeft}
                    >
                      <span aria-hidden='true'>&laquo;</span>
                      <span className='sr-only'>Previous</span>
                    </a>
                  </li>
                );

              if (pageItem === RIGHT_PAGE)
                return (
                  <li key={index} className='page-item'>
                    <a
                      className='page-link'
                      href='#'
                      aria-label='Next'
                      onClick={this.handleMoveRight}
                    >
                      <span aria-hidden='true'>&raquo;</span>
                      <span className='sr-only'>Next</span>
                    </a>
                  </li>
                );

              return (
                <li
                  key={index}
                  className={`page-item${page === pageItem ? ' active' : ''}`}
                >
                  <a
                    className='page-link'
                    href='#'
                    onClick={this.handleClick(pageItem)}
                  >
                    {pageItem}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </Fragment>
    );
  }
}
Pagination.propTypes = {
  total_count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  page_size: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func
};
export default Pagination;
