import React, { Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  RemoveRedEye as ViewIcon,
  Create
} from '@material-ui/icons';
import apiService from '../../../lib/apiService';
import { API_ENDPOINT_USERS } from '../../../lib/apiEndpoints';
import ListData from '../../../components/ListData';
import NewTableList from '../../../components/NewTableList';
// import { debounce } from '../../../lib/helper';
import { connect } from 'react-redux';
import { openModal } from '../../../redux/actions/modalAction';
import ModalParams from '../../../models/modal';
import { CSVLink } from "react-csv";
// import { openModal} from '../../../../redux/actions/modalAction';
// import { Link } from 'react-router-dom';
const UserTable = withRouter(
  ({
    history,
    openModal,
    fetchData,
    filterData,
    data,
    sorting,
    sortChange,
    ...rest
  }) => {
    const onUserDeleteClicked = id => {
      let deleteModal = new ModalParams();
      deleteModal = {
        type: 'confirmation',
        confirmationText: 'Are you sure, you want to delete selected User ?',
        size: 'medium',
        showCloseBtn: true,
        onConfirm: () => {
          apiService
            .request('Delete', API_ENDPOINT_USERS + '/' + id)
            .then(() => fetchData());
        }
      };
      openModal(deleteModal);
      // apiService.request('Delete', API_ENDPOINT_USERS + '/' + id).then(() => {
      //   fetchData();
      //      });
    };
    const columns = [
      {
        name: 'username',
        label: 'Username',
        options: {
          filterType: 'textField',
          filterList: filterData.username ? [filterData.username] : ''
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filterType: 'textField',
          filterList: filterData.email ? [filterData.email] : ''
        }
      },

      {
        name: '_id',
        label: 'Reference',
        options: {
          filterType: 'textField',
          filterList: filterData._id ? [filterData._id] : '',
          customBodyRender: value => {
            let { reference, reference_other } = data.find(
              user => user._id === value
            );
            return reference === 'other' ? reference_other : reference || 'N.A';
          }
        }
      },
      // {
      //   name: 'text_message',
      //   label: 'Text Message',
      //   options: {
      //     filterType: 'textField',
      //     filterList: filterData.text_message ? [filterData.text_message] : ''
      //   }
      // },
      {
        name: 'role',
        label: 'Role',
        options: {
          filterOptions: {
            names: ['admin', 'saller', 'buyer']
          },
          filterList: filterData.role ? [filterData.role] : ''
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : 'customer';
        }
      },
      {
        name: '_id',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowData } = tableMeta;
            return (
              <Fragment>
                <IconButton
                  onClick={() => history.push(`/users/${value}/${rowData[0]}`)}
                >
                  <ViewIcon />
                </IconButton>
                <IconButton onClick={() => onUserDeleteClicked(value)}>
                  <DeleteIcon />
                </IconButton>

                <IconButton
                  onClick={() => history.push(`/users/edit/${value}`)}
                >
                  <Create />
                </IconButton>
              </Fragment>
            );
          }
        }
      }
    ];

    columns.forEach(column => {
      if (column.name === sorting.column_name) {
        let sortDirection = sorting.direction ? sorting.direction : 'none';
        let tempCol = { ...column, sortDirection: sortDirection };
        column.options.options = { ...tempCol };
        // console.log(column);
      }
    });

    //console.log('columns after updates are ', columns);

    return (
      <NewTableList
        columns={columns}
        title='Users'
        fetchData={fetchData}
        data={data}
        {...rest}
        sortChange={sortChange}

      //onFilterChange={onFilterChange}
      />
    );
  }
);

// const onFilterChange = () => {};

const UserList = ({ history, ...rest }) => {

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserData = async() => {
    setLoading(true);
      try {
      const response= await apiService.request("GET", "users");
      const listOfUsers = response.data.filter(user => user.role != "admin")
      .map((user) => user.seller_account ? ({ firstName: user.seller_account.profile.first_name, lastName: user.seller_account.profile.last_name, username: user.username, email: user.email }) : ({ username: user.username, email: user.email }));
      setUserData(listOfUsers);
      setLoading(false);}
      catch(err){
        console.log(err)
      }

  }

  const handleCSVButtonClick = () => {
    getUserData();
  }

  const loadingSpinner = (
    <div className='text-center'>
      <span className="spinner-grow spinner-grow-sm text-dark" role="status" aria-hidden="true"></span>
      <span className="ml-2">Fetching Data...</span>
    </div>
  );

  const hearders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Username", key: "username" }
  ]

  const csvList = {
    data: userData,
    headers: hearders,
    filename: "List_of_user_data.csv"
  };

  console.log(userData.length > 0)

  return (
    <Fragment>
      <Grid container justify='flex-end'>
        <Grid item xs={3} className='createNewUser'>
          <button
            className='btn btn-primary'
            onClick={() => history.push('/users/create')}
          >
            Create New
          </button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ListData
            endpoint='users'
            render={value => <UserTable {...value} {...rest} />}
          />
          {userData.length > 0 ? <CSVLink style={{ minWidth: "225px", minHeight: "38px" }} className='btn btn-primary float-right mt-3' {...csvList}>Export List of Users to CSV</CSVLink>
            :
            <button style={{ minWidth: "225px", minHeight: "38px" }} className='btn btn-primary float-right mt-3' onClick={handleCSVButtonClick}>{!loading ? "Fetch User Data" : loadingSpinner}</button>}
        </Grid>
      </Grid>
    </Fragment>
  );
};

//export default withRouter(UserList);

export default withRouter(connect(null, { openModal })(UserList));
