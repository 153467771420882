import React, { Fragment } from 'react';
import { showErrorToast } from '../../../../redux/actions/toastsAction';

import { connect } from 'react-redux';
import apiService from '../../../../lib/apiService';
import { sellerAccountEndpoint } from '../../../../lib/apiEndpoints';

class SellerBankAccount extends React.Component {
  state = { bankAccount: {}, loading: true };
  accountNumberRef = React.createRef();
  countryRef = React.createRef();
  routingRef = React.createRef();
  componentDidMount() {
    this.fetchBankAccount();
  }
  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };
  fetchBankAccount = () => {
    apiService
      .request('GET', sellerAccountEndpoint(this.props.user_id, 'bank-account'))
      .then(resp => {
        if (resp.data.bank_account) {
          this.setState({ bankAccount: { ...resp.data.bank_account } });
        }
      })
      .catch(err => console.log(err))
      .finally(() => this.toggleLoading());
  };

  onSubmit = e => {
    e.preventDefault();
    this.toggleLoading(true);

    const bankAccount = {
      account_number: this.accountNumberRef.current.value,
      country: this.countryRef.current.value,
      routing_number: this.routingRef.current.value
    };

    if (
      !bankAccount.account_number ||
      !bankAccount.country ||
      !bankAccount.routing_number
    ) {
      this.props.showErrorToast('Please Provide Bank Account Detail');
      // store.dispatch(showErrorToast(''));
      this.toggleLoading(false);
      return;
    }
    apiService
      .request(
        'POST',
        sellerAccountEndpoint(this.props.user_id, 'bank-account'),
        {
          bank_account: { ...bankAccount }
        }
      )
      .then(resp => {
        this.setState({ bankAccount: { ...resp.data.bank_account } });
        if (this.props.onClose) {
          this.props.onClose(this.state.bankAccount);
        }
      })
      .finally(() => this.toggleLoading(false));
  };

  render() {
    const { bankAccount } = this.state;
    return (
      <Fragment>
        {!bankAccount ? (
          ''
        ) : (
          <div>
            <div className='text-secondary'>
              This will replace any previously entered information.
            </div>
            <hr />
            <div className='text-secondary'>
              <div>Linked Account</div>
              <h5 className='text-uppercase text-dark mb-3'>
                {bankAccount.bank_name} <br />
                **** {bankAccount.last4} - {bankAccount.currency}
              </h5>
            </div>
          </div>
        )}

        <div className='text-secondary'>
          <small>
            You can locate your Routing number and Account number on a bank
            check.
          </small>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className='form-group'>
            <label htmlFor='country'>Bank Country</label> <sup> *</sup>
            <select
              name='country'
              ref={this.countryRef}
              className='form-control'
            >
              <option value='US'>US</option>
            </select>
          </div>
          <div className='form-group'>
            <label htmlFor='account-number'>Account Number</label> <sup> *</sup>
            <input
              type='text'
              className='form-control'
              name='account_number'
              ref={this.accountNumberRef}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='routing-number'>Routing Number</label> <sup> *</sup>
            <input
              type='text'
              className='form-control'
              name='routing_number'
              ref={this.routingRef}
            />
          </div>
          <button type='submit' className='btn btn-primary'>
            Update Bank Account
          </button>
        </form>
      </Fragment>
    );
  }
}

// export default SellerBankAccount;

export default connect(
  null,
  { showErrorToast }
)(SellerBankAccount);
