import React from 'react';
import { Container } from '@material-ui/core';
import SwapDetails from './components/SwapDetails';

const Swap = () => {
  //const { swap_id } = props.match.params;
  return (
    <Container>
      <SwapDetails />
    </Container>
  );
};

export default Swap;
