import React from 'react';
import { Container } from '@material-ui/core';
import CategoryForm from '../component/CategoryForm';

const CategoryEdit = () => {
  return (
    <Container>
      <CategoryForm />
    </Container>
  );
};

export default CategoryEdit;
