import React from 'react';
import CategoryAttributeItem from './CategoryAttributeItem';

const CategoryAttributes = ({ attributes = [], onChange }) => {
  //const [attributes, updateAttributes] = useState(categoryAttributes);
  console.log(attributes);
  const onAttributeChange = (index, attribute) => {
    onChange(
      attributes.map((item, key) => {
        if (key === index) {
          return attribute;
        }
        return item;
      })
    );
  };
  const onAttributeRemove = index => {
    onChange(attributes.filter((attribute, key) => key !== index));
  };
  const onAdd = () => {
    onChange([...attributes, {}]);
  };
  const items = attributes.map((attribute, index) => (
    <div key={index}>
      <CategoryAttributeItem
        attribute={attribute}
        index={index}
        onChange={attribute => onAttributeChange(index, attribute)}
        onRemove={() => onAttributeRemove(index)}
      />
    </div>
  ));
  return (
    <>
      {items}
      <button
        className='btn btn-outline-secondary'
        type='button'
        onClick={onAdd}
      >
        Add New Attribute
      </button>
    </>
  );
};

export default React.memo(CategoryAttributes);
