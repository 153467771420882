import React from 'react';
import apiService from '../../../../lib/apiService';
import OrderSummary from './OrderSummary';
//import { SwapModel } from '../../../../store/shared/models/swaps';
import SwapActions from './SwapActions';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {
	API_ENDPOINT_SWAPS,
	swapOfferUpdateEndpoint,
	API_ENDPOINT_NOTES,
	swapsEndpoint2
} from '../../../../lib/apiEndpoints';
import {SwapModel} from '../../../../models/swaps'
import Loading from '../../../../components/loading';
import {openModal} from '../../../../redux/actions/modalAction';
import {connect} from 'react-redux';
import {Card,CardContent,Typography} from '@material-ui/core';
import {destructSwap,frontendAppUrl} from '../../../../lib/helper';
import SwapMessagesPanel from '../../swapMessagesPanel/index';
import NotePanel from '../../../../components/note';

class SwapDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			loading: true,
			swap: new SwapModel(),
			buyerOrSeller: '',
			isSubmitting: false,
			sendingMessage: false,
			notes: [],
			showChatWindow: false
		};
	}
	componentDidMount() {
		this.fetchSwap();
		this.fetchNotes();
	}
	fetchNotes=() => {
		const {swap_id}=this.props.match.params;
		// const swap_id="5f3174f91081dd5580360e62"
		apiService
			.request('GET',`swaps/${swap_id}/${API_ENDPOINT_NOTES}`)
			.then(resp => {
				const note=resp.data;

				this.setState({
					notes: [...this.state.notes,...note],
					//buyerOrSeller: user.user_id === swap.buyer_id ? 'buyer' : 'seller',
					loading: false
				});


			}).catch(err => console.log(err,this.toggleLoading(false)));
	};

	fetchSwap=() => {
		const {swap_id}=this.props.match.params;
		apiService
			.request('GET',API_ENDPOINT_SWAPS+`/${swap_id}`)
			.then(resp => {
				const {swap}=resp.data;
				//const { user } = this.props.state;
				console.log(swap);
				this.setState({
					swap: {...swap},
					//buyerOrSeller: user.user_id === swap.buyer_id ? 'buyer' : 'seller',
					loading: false
				});
			})

			.catch(err => console.log(err,this.toggleLoading(false)));
	};
	getCurrentOffer=(offers,offerId) => {
		return Object.values(offers).find(offer => offer._id===offerId);
	};

	toggleLoading=(flag=false) => {
		this.setState({loading: flag});
	};
	toggleIsSubmitting=(flag=false) => {
		this.setState({loading: flag});
	};
	onActionButtonClick=type => {
		const {openModal}=this.props;
		openModal({
			type: 'confirmation',
			confirmationText: 'Are you sure you want to proceed?',
			size: 'small',
			onConfirm: () => this.performSwapAction(type)
		});
	};
	performSwapAction=type => {
		const {swap}=this.state;
		this.toggleIsSubmitting(true);
		apiService
			.request(
				'PUT',
				swapOfferUpdateEndpoint(swap._id,swap.current_offer_id,type)
			)
			.then(resp => {
				if(resp.data.swap) {
					this.setState({
						swap: {...resp.data.swap}
					});
				}
			})
			.catch(err => console.log(err))
			.finally(() => this.toggleIsSubmitting());
	};

	sendMessage=(messageBody) => {
		const {swap_id}=this.props.match.params;
		const {swap}=this.state;
		this.setState({sendingMessage: true});
		apiService
			.request('POST',swapsEndpoint2(swap._id,'messages'),{
				message: {body: messageBody},
			})
			.then((resp) => {
				const {swap}=this.state;
				this.setState({
					swap: {...swap,messages: [...swap.messages,{...resp.data}]},
				});
			})
			.finally(() => this.setState({sendingMessage: false}));
	};

	addNote=(messageBody) => {
		const {swap_id}=this.props.match.params;
		const {swap}=this.state;
		this.setState({sendingMessage: true});
		apiService
			.request('POST',swapsEndpoint2(swap._id,'notes'),{
				"text": messageBody,
				"swap_id": swap_id
			})
			.then((resp) => {
				console.log(resp)
				let note=resp.data
				const {notes}=this.state;
				this.setState({
					notes: [...this.state.notes,note],
				});
			})
			.finally(() => this.setState({sendingMessage: false}));
	};

	deleteNote = (message_id)=>{
		console.log("swap lndcac")
		const {swap_id}=this.props.match.params;
		apiService
			.request('DELETE',`swaps/${swap_id}/notes/${message_id}`)
			.then((resp) => {
				console.log(resp)
				if(resp.status){
					let note=this.state.notes.filter(val=>{
						return (val._id !== message_id)
					})
					
					this.setState({
					notes: [...note]})
				}
				
			
			})
	}
	// showChats=() => {
	// 	this.setState({showChatWindow:true})
	// }
	render() {
		const {swap,loading,notes}=this.state;
		const {buyer,seller,item}=destructSwap(swap);

		if(loading) {
			return <Loading />;
		}

		if(!swap) {
			return 'Swap Details Not Found';
		}
		return (
			<div className='row'>
				<div className='col-9'>
					<Card>
						<CardContent>
							<Typography variant='h5' component='h5'>
								Swap# {swap._id}
							</Typography>
							<Typography component='p'>
								Date Created: {swap.created_at}
							</Typography>

							<div className='row mt-3'>
								<div className='col'>
									<div>
										<b>Vendor:</b>{' '}
										<Link to={`/sellers/${seller._id}/${seller.username}`}>
											@{seller.username}
										</Link>
									</div>
									<div>
										<b>Buyer:</b>{' '}
										<Link to={`/users/${buyer._id}/${buyer.username}`}>
											@{buyer.username}
										</Link>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
				
					<div className="pt-5"><SwapMessagesPanel
						messages={swap.messages}
						sendMessage={this.sendMessage}
						sendingMessage={this.state.sendingMessage}
					/></div>

						<div className="pt-5">
						<NotePanel notes={notes} addNote={this.addNote}  deleteNote={this.deleteNote}/>
						{/* deleteNote={this.deleteNote} */}
					</div>

				</div>
				<div className='col-3'>
					<div className='card'>
						{/* <Link to={`/product/${item._id}`}> */}
						<img
							className='card-img-top'
							onClick={() =>
								window.open(frontendAppUrl()+`/product/${item._id}`)
							}
							src={
								item.images.length>0
									? item.images[0].image_url
									:'https://via.placeholder.com/150'
							}
							alt='Card  cap'
						/>
						{/* </Link> */}
						<div className='card-body'>
							<a
							target="_blank"
							rel="noopener noreferrer" 
							href={frontendAppUrl()+`/product/${item._id}`}
							>
								<p
									// onClick={() =>
									//   window.open(frontendAppUrl() + `/product/${item._id}`)
									// }
									className='card-title'
								>
									{item.name}
								</p>
							</a>
							<p className='card-subtitle'>
								Ask Price: <b>${item.price}</b>
							</p>
							{swap.offers.length>0&&swap.current_offer_id? (
								<OrderSummary
									offer={this.getCurrentOffer(
										swap.offers,
										swap.current_offer_id
									)}
									transaction={swap.transaction}
								/>
							):(
									''
								)}
							<div className='swap-state-label'>
								<p>
									<b>{swap.label}</b>
								</p>
							</div>



							<SwapActions
								swap={swap}
								onActionButtonClick={this.onActionButtonClick}
								reloadSwap={this.fetchSwap}
							/>
						</div>



					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(
	connect(
		null,
		{openModal}
	)(SwapDetails)
);
