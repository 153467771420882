import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const defaultStore = {
  // app: {
  //   settings: {
  //     language: 'en',
  //     currency_code: 'USD',
  //     currency_symbol: '$',
  //     currency_format: '${amount}',
  //     thousand_separator: '',
  //     decimal_separator: '.',
  //     decimal_number: 2,
  //     timezone: 'Asia/Singapore',
  //     date_format: 'MMMM D, YYYY',
  //     time_format: 'h:mm a',
  //     weight_unit: 'kg',
  //     length_unit: 'cm'
  //   },
  //   categoryDetails: {},
  //   productDetails: {},
  //   categories: [],
  //   products: [],
  //   productsTotalCount: 0,
  //   productsHasMore: false,
  //   productsMinPrice: 0,
  //   productsMaxPrice: 0,
  //   productsAttributes: [],
  //   paymentMethods: [],
  //   shippingMethods: [],
  //   loadingProducts: false,
  //   loadingMoreProducts: false,
  //   loadingShippingMethods: false,
  //   loadingPaymentMethods: false,
  //   processingCheckout: false
  //   //cart: cart,
  //   //order: null,
  //   //checkoutFields: checkoutFields,
  //   //themeSettings: themeSettings
  // },
  // form: {},
  toasts: [],
  modals: [],
  user: {},
  data: {},
};
// Create a redux store by passing the combined reducers or a single reducer
const store = createStore(
  reducers,
  defaultStore,
  applyMiddleware(thunk)
  // compose(
  //   applyMiddleware(thunk),
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )

  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
