import axios from 'axios';
import store from '../redux/store';
import { API_ENDPOINT_UPLOAD_IMAGES } from './apiEndpoints';
import {
  showErrorToast,
  showSuccessToast,
} from '../redux/actions/toastsAction';

const Baseurl: any = {
  url: process.env.REACT_APP_API_URL,
};
let user;
export const apiService = {
  updateUser: (userData = {}) => {
    user = { ...userData };
  },
  request(
    method = 'POST',
    endpoint: string | number,
    data: any | {} = {},
    showErrorAlert = true
  ) {
    const token = store.getState().user.token || '';
    // let token = localStorage.getItem(USER_TOKEN);

    const requestParams = { url: Baseurl.url + endpoint, method };
    const isFormData = data instanceof FormData;
    const headers = {
      'Content-Type': !isFormData
        ? 'application/json;charset=UTF-8'
        : 'multipart/form-data',
    };

    if (token) {
      Object.assign(headers, { Authorization: `Bearer ${token}` });
    }
    Object.assign(requestParams, { headers: { ...headers } });
    const params = isFormData ? data : JSON.stringify(data);
    if (method === 'GET') {
      Object.assign(requestParams, {
        params: data,
      });
    } else if (data) {
      Object.assign(requestParams, {
        data: params,
      });
    }
    // else {
    //   Object.assign(requestParams, { params: params });
    // }
    return new Promise((resolve, reject) => {
      axios(requestParams)
        // .then(resp => resp.data.json())
        .then(
          (resp) => {
            let msg;
            if (resp.status && method === 'DELETE') {
              msg = 'Note Deleted Successfully';
              store.dispatch(showSuccessToast(msg));
            } else if (!resp.status && method === 'DELETE') {
              msg = 'Somthing Went Wrong...';
              store.dispatch(showErrorToast(msg));
            }
            if (resp.data.status === true) {
              handleApiSuccess(resp.data, resolve);
            } else {
              handleApiError(resp.data, showErrorAlert, reject);
            }
          },
          (err) => handleApiError(err, showErrorAlert, reject)
        );
    });
  },
  uploadImage(data: FormData, showErrorAlert = false) {
    return new Promise((resolve, reject) => {
      axios
        .post(Baseurl.url + API_ENDPOINT_UPLOAD_IMAGES, data, {
          headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data;`,
          },
        })
        .then((resp) => {
          if (resp.data.status === true) {
            handleApiSuccess(resp.data.data.images, resolve);
          } else {
            handleApiError(resp.data, showErrorAlert, reject);
          }
        })
        .catch((err) => {});
    });
  },
};
const handleApiError = (
  err: { message: any },
  showAlert: boolean,
  reject: any
) => {
  if (showAlert) {
    const msg = err.message
      ? err.message
      : 'Something went wrong, please try again later';
    store.dispatch(showErrorToast(msg));
  }
  if (reject) {
    reject(err);
  }
};
const handleApiSuccess = (resp: { message: any }, resolve: any) => {
  if (resp.message && resp.message !== 'success') {
    store.dispatch(showSuccessToast(resp.message));
  }
  if (resolve) {
    resolve(resp);
  }
};
export default apiService;
