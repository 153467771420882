import React from 'react';
import apiService from '../../../../lib/apiService';
import { API_ENDPOINT_CATEGORIES } from '../../../../lib/apiEndpoints';
import { withRouter } from 'react-router-dom';
//PureComponent re-renders only if the props pased to component changes!
class CategoriesDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: []
    };
  }
  componentDidMount() {
    this.fetchAllCategories();

    // let foundCategory = this.state.categories.map((value, key) => {
    //   if (value._id === this.props.category_id) {
    //     return value;
    //   }
    // });
    // console.log('child cat found is ', foundCategory);
  }
  fetchAllCategories = () => {
    //fetch all categories
    apiService
      .request('GET', API_ENDPOINT_CATEGORIES)
      .then(resp => {
        if (resp.data.categories.length > 0) {
          //console.log(this.parseCategories(resp.data.categories));
          this.setState({
            categories: this.parseCategories(resp.data.categories)
          });
        }
      })
      .catch(err => console.log(err));
    //remove this line when connected to API
    // this.parseCategories([...CATEGORIES_LIST]);
  };
  parseCategories = categories => {
    return categories
      .map(category => {
        if (category.children && category.children.length > 0) {
          return [category, ...this.parseCategories(category.children)];
        } else {
          return category;
        }
      })
      .flat();
  };
  handleCategoryChange(cat_id) {
    // let foundCategory = this.state.categories.map((value, key) => {
    //   if (value._id === cat_id) {
    //     return value;
    //   }
    // });

    let foundCategory = this.state.categories.find(
      element => element._id === cat_id
    );
    // console.log('single cat found is ', foundCategory);

    this.props.handleCategoryChange(foundCategory);
  }
  // getChildren = categories => {
  //   return categories.map(category => {
  //     if (category.children && category.children.length > 0) {
  //       return this.getChildren(category.children);
  //     }
  //     return category;
  //   });
  // };
  render() {
    const { categories } = this.state;

    return (
      <div className='form-group'>
        <label htmlFor='product-category'>
          {this.props.title || 'Category'}
        </label>
        <select
          className='form-control'
          id='product-category'
          value={this.props.category_id}
          onChange={e => this.handleCategoryChange(e.target.value)}
          // onChange={() => this.props.getBaseCategory(foundCategory)}
        >
          <option value='' />
          {categories.length > 0
            ? categories
                .filter(
                  category =>
                    category._id !== this.props.match.params.category_id
                )
                .map(category => (
                  <option key={category._id} value={category._id}>
                    {category.full_name}
                  </option>
                ))
            : ''}
        </select>
      </div>
    );
  }
}

export default withRouter(CategoriesDropdown);
