import { USER_LOGIN, USER_LOGOUT, USER_REGISTER, SET_USER_ADDRESS } from '../actionTypes';

export const userLogin = (payload: any) => {
  return {
    type: USER_LOGIN,
    payload: {
      ...payload
    }
  };
};
export const userRegister = (payload: any) => {
  return {
    type: USER_REGISTER,
    payload: {
      ...payload
    }
  };
};
export const userLogut = () => {
  return {
    type: USER_LOGOUT,
    payload: {}
  };
};
export const setUserAddress = (payload: any) => {
  return {
    type: SET_USER_ADDRESS,
    payload: { ...payload }
  };
};
