import React from 'react';
import { connect } from 'react-redux';

import { openModal } from '../../../../redux/actions/modalAction';

import {
  SWAP_SHIPMENT_STATE,
  SWAP_ACTION_TRACK,
  SWAP_PROCESSING_STATE,
  SWAP_SHIPPED_STATE,
  SWAP_RECEIVED_STATE,
  SWAP_ACTION_CANCELED,
  SWAP_ACTION_SHIPPED,
  SWAP_ACTION_COMPLETED,
  SWAP_DISPUTED_STATE,
  SWAP_ACTION_DELAYED,
  SWAP_APPROVED_STATE,
  SWAP_ON_HOLD_STATE,
  SWAP_DECLINED_STATE,
  SWAP_CANCELED_STATE,
  SWAP_PAYMENT_PENDING_STATE,
  SWAP_DELAYED_STATE,
  SWAP_COMPLETED_STATE
} from '../../../../models/swaps';
import SwapShippedForm from './SwapShippedForm';
import DelayedForm from './swapDelayed';

const SwapActions = ({ swap, onActionButtonClick, reloadSwap, openModal }) => {
  //Action buttons available to seller with respect to swap state
  const getAdminSwapActions = state => {
    let actions = [];
    switch (state) {
      case SWAP_PAYMENT_PENDING_STATE:
        return [
          {type: SWAP_PROCESSING_STATE, label: 'Payment Received'},
          {type: SWAP_ON_HOLD_STATE, label: 'On Hold'},
          {type: SWAP_CANCELED_STATE, label: 'Cancel Swap'},
        ]
      case SWAP_PROCESSING_STATE:
        return [
          {type: SWAP_APPROVED_STATE, label: 'Manual Approval'},
          {type: SWAP_ON_HOLD_STATE, label: 'On Hold'},
          {type: SWAP_CANCELED_STATE, label: 'Cancel Swap'},
        ];
      case SWAP_ON_HOLD_STATE:
      case SWAP_DECLINED_STATE:
        return [
           {type: SWAP_APPROVED_STATE, label: 'Manual Approval'},
           {type: SWAP_CANCELED_STATE, label: 'Cancel Swap'},
           { type: SWAP_ACTION_COMPLETED, label: 'Completed' },
        ];
      case SWAP_APPROVED_STATE:  
      case SWAP_SHIPMENT_STATE:
        return [
          { type: SWAP_ACTION_TRACK, label: 'Track Package' },
          { type: SWAP_ACTION_SHIPPED, label: 'Shipped' },
          { type: SWAP_ACTION_COMPLETED, label: 'Completed' },
          { type: SWAP_ACTION_CANCELED, label: 'Cancel Swap' },
          { type: SWAP_ACTION_DELAYED, label: 'Delayed' },
          
        ];
      case SWAP_SHIPPED_STATE:
      case SWAP_RECEIVED_STATE:
        return [
          { type: SWAP_ACTION_TRACK, label: 'Track Package' },
          { type: SWAP_ACTION_COMPLETED, label: 'Completed' },
          { type: SWAP_ACTION_CANCELED, label: 'Cancel Swap' },
         
        ];

      case SWAP_DISPUTED_STATE:
        return [
          { type: SWAP_ACTION_TRACK, label: 'Track Package' },
          { type: SWAP_ACTION_COMPLETED, label: 'Completed' },
          { type: SWAP_ACTION_CANCELED, label: 'Cancel Swap' },
         
        ];
      case SWAP_DELAYED_STATE:
        return [
          { type: SWAP_ACTION_TRACK, label: 'Track Package' },
          { type: SWAP_ACTION_COMPLETED, label: 'Completed' },
          { type: SWAP_ACTION_CANCELED, label: 'Cancel Swap' },
        ]; 
      case SWAP_COMPLETED_STATE:
        return [      
          { type:SWAP_ACTION_TRACK, label:'Track Package'},
        ]
      default:    
        actions = [];
    }   
    return actions;
  };   

  const swapActions = () => {
    const { state } = swap;
    return getAdminSwapActions(state);
  };
  const handleActionButtonClick = type => {
    switch (type) {
      case SWAP_ACTION_SHIPPED:
        openModal({
          type: 'custom',
          content: <SwapShippedForm reloadSwap={reloadSwap} swap={swap} />,
          size: 'small',
          title: 'Swap Shipped'
        });
        break;
        case SWAP_ACTION_DELAYED:
        openModal({
          type: 'custom',
          content: <DelayedForm reloadSwap={reloadSwap} swap={swap} />,
          size: 'small',
          title: 'Swap Delayed'
        });
        break;
      case SWAP_ACTION_TRACK:
        //redirect to track package page
        window.open(swap.tracker_url, '_blank');
        // history.push(swap.tracker_url);
        break;
      // case SWAP_ACTION_CANCELED:
      //   openModal({
      //     type: 'custom',
      //     content: <SwapCancelForm reloadSwap={reloadSwap} swap={swap} />,
      //     size: 'small',
      //     title: 'Swap Cancellation'
      //   });
      //   break;
      default:
        onActionButtonClick(type);
        break;
    }
  };

  return (
    <div className='swap-action-buttons'>
      {swapActions().map((action, index) => (
        <button
          type='button'
          onClick={() => handleActionButtonClick(action.type)}
          className={`btn ${
            index % 2 ? 'btn-outline-secondary' : 'btn-primary'
          } btn-block`}
          key={index}
        >
          {action.label}
        </button>
      ))}
    </div>
  );
};
export default connect(
  null,
  { openModal }
)(SwapActions);
