import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
// import logo from '../logo.jpg';
import authService from '../../lib/auth';
import { withRouter } from 'react-router-dom';
const Login = props => {
  const [isLoggedIn, toggleLoggedIn] = useState(authService.isLoggedIn());

  const emailRef = React.createRef();
  const passwordRef = React.createRef();
  const onLogin = event => {
    event.preventDefault();
    let email_username = emailRef.current.value;
    let password = passwordRef.current.value;

    if (!email_username) {
      alert('Please enter a username or email ID');
      return;
    }
    if (!password) {
      alert('Please enter a password');
      return;
    }
    //Call authenticate method of AuthService constant and pass email, password and callback function
    authService.authenticate({ email_username, password }).then(() => {
      toggleLoggedIn(true);
    });
  };
  const renderLoginForm = () => {
    return (
      <div className='container'>
        <div className='row align-middle mt-5'>
          <div className='col-md-4 offset-md-4'>
            <div className='card'>
              <div className='card-body'>
                <h3 className='text-center'>Login</h3>
                <form onSubmit={onLogin}>
                  <div className='form-group'>
                    <label htmlFor='email'>Email</label>
                    <input
                      ref={emailRef}
                      name='email'
                      type='text'
                      id='email'
                      className='form-control'
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='password'>Password</label>
                    <input
                      ref={passwordRef}
                      name='password'
                      type='password'
                      id='password'
                      className='form-control'
                    />
                  </div>
                  <button type='submit' className='btn btn-primary'>
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className='logo mb-4'>
              {/* <img src={logo} alt='logo' className='mx-auto d-block' /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  //get from(referrer) from props.location.state if set
  // let { from } = props.location.state || { from: { pathname: '/dashboard' } };
  //if isLoggedIn is true then redirect to the referrer OR Redirect to root after successful login
  if (isLoggedIn) return <Redirect to='/dashboard' />;
  //render login form if user not logged in
  return renderLoginForm();
};

export default withRouter(Login);
