import React from "react";
import {formatEpocDate} from "../../lib/helper";

import store from '../../redux/store';




const NoteItem=({message,noteDeleted}) => {
	const user=store.getState().user;
	
	console.log(user)
	console.log(message)

	const messageFrom=user.user_id!==message.creater_id? "theirs":"mine";
	const deleteNote=(message_id) => {
					noteDeleted(message_id);

	
	};
  
	return (
		<>
			<div className={`swap-note-container ${messageFrom}`}>
				<div>
					{/* {messageFrom==="theirs"&&(
						<div className="slsfb swap-note-username-row slsfb_aic">
							<Link
								className="swap-note--username"

							>
								{message.sender.username}
							</Link>
						</div>
					)} */}
					<pre
						className={`text-break swap-note swap-note--${messageFrom} ${message.tone&&`swap-note--${message.tone}`
							}`}
					>
						{message.text}
					</pre>

					<p className={`note-time note-time--${messageFrom}`}>
						<small>{formatEpocDate(message.updated_at,true)}</small>
						<small className="ml-2"><a href="javascript:void(0)" onClick={()=>{deleteNote(message._id)}} >Delete</a></small>
					</p>
				</div>
				{/* <DeleteIcon onClick={()=>{deleteNote(message._id)}} style={{color: 'red',paddingTop: '2px',cursor: "pointer",marginTop: "6px"}} /> */}

				{/* onClick={deleteNote(message._id)} */}
			</div>
		</>
	);
};

export default NoteItem;
