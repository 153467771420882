import React from "react";

const UserForm = props => {
  const { user, onInputChange, onSubmit } = props;

  return (
    <form className="form">
      <div className="form-group">
        <label>Username</label>
        <input
          className="form-control"
          type="text"
          name="username"
          value={user.username || ""}
          onChange={onInputChange}
          autoComplete="off"
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={user.email || ""}
          onChange={onInputChange}
          autoComplete="off"
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          name={user._id?"new_password":"password"}
          value={user._id?user.new_password :user.password}
          onChange={onInputChange}
          autoComplete="off"
        />
      </div>
      <div className="form-group">
        <label>Role</label>
        <select
          className="form-control"
          name="role"
          value={user.role || ""}
          onChange={onInputChange}
        >
          <option value="customer">Customer</option>
          <option value="support">Support</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <button type="button" className="btn btn-primary" onClick={onSubmit}>
        Submit
      </button>
    </form>
  );
};

export default UserForm;
