import { SWAP_DECLINED_STATE, SWAP_APPROVED_STATE, SWAP_ON_HOLD_STATE, SWAP_PAYMENT_PENDING_STATE, SWAP_PROCESSING_STATE } from './swaps';

export const SORTING = {
  NAME: 'name',
  NEWEST: 'date',
  PRICELOW: 'price',
  PRICEHIGH: '-price'
};

export const swapStates = {
  message: 'message',
  offer: 'offer pending',
  withdrawn: 'offer withdrawn',
  rejected: 'offer rejected',
  accepted: 'offer accepted',
  label: 'pending Shipping Label',
  shipment: 'shipment Pending',
  shipped: 'shipped',
  approval: 'pending Buyer Approval',
  dispute: 'pending Dispute',
  canceled: 'canceled',
  completed: 'completed',
  [SWAP_DECLINED_STATE]: 'Declined',
  [SWAP_APPROVED_STATE]: 'Approved',
  [SWAP_ON_HOLD_STATE]: 'On Hold',
  [SWAP_PAYMENT_PENDING_STATE]: 'Payment Pending',
  [SWAP_PROCESSING_STATE]: 'Pending Approval'
  
};
// export const swapStates = [
//   'offer pending',
//   'offer withdrawn',
//   'offer rejected',
//   'offer accepted',
//   'Pending Shipping Label',
//   'Shipment Pending',
//   'Shipped',
//   'Pending Buyer Approval',
//   'Pending Dispute',
//   'Canceled',
//   'Completed'
// ];
