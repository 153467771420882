import React from 'react';
import SidebarMenu from '../../../../components/SidebarMenu';
const SellerDetailsSidebar = ({ user_id, username, scope, location }) => {
  const menuItems = [
    {
      title: 'Dashboard',
      url: `/sellers/${user_id}/${username}/`
    },
    {
      title: 'Listings',
      url: `/sellers/${user_id}/${username}/listings`
    },
    {
      title: 'Swaps',
      url: `/sellers/${user_id}/${username}/swaps`
      //url: `/swaps/${username}`
    },
    {
      title: 'Feedbacks',
      url: `/sellers/${user_id}/${username}/feedback`
    }
  ];
  return <SidebarMenu menuItems={menuItems} />;
};

export default SellerDetailsSidebar;
