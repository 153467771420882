/* eslint-disable no-restricted-globals */
import apiService from './apiService';
import { API_ENDPOINT_LOGIN, API_ENDPOINT_REGISTER } from './apiEndpoints';
import store from '../redux/store';
import { userLogin, userLogut } from '../redux/actions/userAction';

const LOGIN_PATH = '/login';
// const HOME_PATH = "/dashboard";
export const validateCurrentToken = () => {
  if (location.pathname !== LOGIN_PATH) {
    const user = authService.isLoggedIn();
    if (!user) {
      location.replace(LOGIN_PATH);
    } else {
      store.dispatch(
        userLogin({
          ...user
        })
      );
    }
  }
};

// const isCurrentTokenValid = () => {
//   const expiration_date = localStorage.getItem('dashboard_exp');
//   return localStorage.getItem('dashboard_token') && expiration_date && expiration_date > Date.now();
// };
// const saveToken = data => {
//   localStorage.setItem('dashboard_token', data.token);
//   localStorage.setItem('dashboard_email', data.email);
//   localStorage.setItem('dashboard_exp', data.expiration_date);
// };
// export const removeToken = () => {
//   localStorage.removeItem('dashboard_token');
//   localStorage.removeItem('dashboard_email');
//   localStorage.removeItem('dashboard_exp');
//   localStorage.removeItem('webstore_token');
//   localStorage.removeItem('webstore_email');
//   localStorage.removeItem('webstore_exp');
//   location.replace(LOGIN_PATH);
// };

const authService = {
  // setUserAuthOnPageLoad() {
  //   const user = this.isLoggedIn();
  //   if (user) {
  //     store.dispatch(
  //       userLogin({
  //         ...user
  //       })
  //     );
  //   }
  // },
  isLoggedIn() {
    //check redux or localstorage to check if user is logged in
    const user = JSON.parse(localStorage.getItem('dashboard_user'));
    return !user || Object.entries(user).length === 0 ? false : user;
  },

  authenticate(creds) {
    // call login API
    return new Promise((resolve, reject) => {
      apiService
        .request('POST', API_ENDPOINT_LOGIN, creds)
        .then(resp => {
          setUserDetails(resp.data);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  register(creds) {
    return new Promise((resolve, reject) => {
      apiService
        .request('POST', API_ENDPOINT_REGISTER, creds)
        .then(resp => {
          setUserDetails(resp.data);
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });

    // comment the below line when linked with Login API

    //setTimeout(successCallback);
  },

  signout(cb) {
    //call logout API
    localStorage.setItem('dashboard_user', JSON.stringify({}));
    store.dispatch(userLogut());
    setTimeout(cb);
  }
};
const setUserDetails = (user = {}) => {
  localStorage.setItem('dashboard_user', JSON.stringify(user));
  store.dispatch(userLogin({ ...user }));
};
export default authService;
