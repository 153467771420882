import React, { Fragment } from 'react';
export default class FileUpload extends React.Component {
  fileRef = React.createRef();
  onImageCardClick = () => {
    if (this.props.imageSrc) {
      return;
    }
    this.fileRef.current.click();
  };
  handleFiles = event => {
    event.stopPropagation();

    let files = this.fileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.type.startsWith('image/')) {
        continue;
      }
      this.props.onUpload(file, URL.createObjectURL(file));
    }
  };

  getImageSrc() {}
  render() {
    return (
      <Fragment>
        <input
          ref={this.fileRef}
          type='file'
          style={{ display: 'none' }}
          onChange={this.handleFiles}
          accept='image/jpeg'
        />
        <div className='card'>
          <img
            onClick={this.onImageCardClick}
            src={
              this.props.imageSrc ||
              'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-camera-128.png'
            }
            className='card-img-top'
            alt=''
            style={{ maxHeight: '200px' }}
          />
          {this.props.imageSrc ? (
            <div className='card-body text-center'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => this.props.onRemoveClick(this.props.imageSrc)}
              >
                Delete
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </Fragment>
    );
  }
}
