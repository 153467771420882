import React, { Fragment } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { PieCharts } from './Charts';
import DashboardBody from './Dashboard-body';

const DashboardDailyUsers = ({
  pieDataDaily,
  selection,
  pieValuesDaily,
  colorsDaily,
  handleChange,
  listOfData,
  days,
  totalUsers,
  years,
  indexDaily,
  handleindexButton,
  facebook,
  instagram,
  google,
  discord,
  text,
  other,
  craiglist,
  youtube,
  unavailable,
}) => (
  <>
    <DashboardBody
      title="Users"
      data={years}
      selection={selection}
      index={indexDaily}
      handleindexButton={handleindexButton}
      handleChange={handleChange}
    >
      <div className="col-sm-6">
        <LineChart
          width={650}
          height={500}
          data={listOfData}
          margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={days} />
          <YAxis />
          <Tooltip itemSorter={(item) => -item.value} filterNull />
          <Legend />
          <Line
            type="monotone"
            dataKey={totalUsers}
            stroke="#E8840E"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={facebook}
            stroke="#e84855"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={instagram}
            stroke="#e8170e"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={google}
            stroke="#372e29"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={discord}
            stroke="#DFE80E"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={text}
            stroke="#c74375"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={other}
            stroke="#FF8042"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={craiglist}
            stroke="#FFBB28"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={youtube}
            stroke="#00C49F"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={unavailable}
            stroke="#0088FE"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </div>
      <div className="col-sm-6">
        {pieDataDaily && pieDataDaily.length > 0 ? (
          <PieCharts
            listOfData={pieDataDaily}
            dataIndex={indexDaily}
            values={pieValuesDaily}
            colors={colorsDaily}
          />
        ) : (
          <div
            style={{ height: '500px' }}
            className="d-flex align-items-center justify-content-center"
          >
            <h1>No Data to display.</h1>
          </div>
        )}
      </div>
    </DashboardBody>
  </>
);

export default DashboardDailyUsers;
