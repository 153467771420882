import React,{useEffect} from 'react';
import NoteItem from './noteItem';

const DisplayNotes=({messages=[],deleteNote}) => {
	const listRef=React.createRef();
	const scrollToBottom=() => {
		listRef.current.scrollTop=listRef.current.scrollHeight-listRef.current.offsetHeight;
	};
	useEffect(() => {
		if(listRef) {
			scrollToBottom();
		}
	},[messages.length]);

	const noteDeleted=(id)=>{
		deleteNote(id);
	}
	return (
		<div className='note-list' ref={listRef}>
			<>
			{/* deleteNote={deleteNote} */}
				{messages.map(message => (
					<NoteItem key={message._id} message={message} noteDeleted={noteDeleted} />
				))}
			</>
		</div>
	);
};

export default React.memo(DisplayNotes);
