import React from 'react';
import { NavLink } from 'react-router-dom';

const Options = ({ options }) => {
  const styles = {
    nav: {
      border: '1px solid #ddd',
      borderRadius: '5px',
    },
    options: {
      color: '#4d4b4b',
      padding: '3px',
      fontSize: '16px',
      fontWeight: 'bolder',
    },
  };

  return (
    <div style={styles.nav} className="navbar row mb-3">
      {options.map((option, index) => (
        <div
          key={index}
          className={`col-sm-${12 / options.length} text-center`}
        >
          <NavLink
            style={styles.options}
            activeStyle={{ borderBottom: '2px #4d4b4b solid' }}
            exact
            to={option.route}
          >
            {option.name}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Options;
