import React from 'react';
import TransactionItem from '../../../../components/transactions/TransactionItem';
import ListHOC from '../../../../components/ListHoc';
import { compose } from 'redux';
import { transactionsApiParams } from '../../../../lib/apiEndpoints';

const TransactionsHOC = WrappedComponent => {
  const Container = props => {
    return <WrappedComponent {...props} apiParams={transactionsApiParams(['sale'])} />;
  };
  return Container;
};
const SaleTransactions = compose(
  TransactionsHOC,
  ListHOC({ defaultMessage: 'No transactions Found', type: 'list' })
)(TransactionItem);

export default SaleTransactions;
