import React, { memo, useState } from 'react';
import TransactionItemDetails from './TransactionItemDetails';
import { withRouter } from 'react-router';
import { formatDate } from '../../lib/helper';

const TransactionItem = ({ item }) => {
  const [showDetails, toggleShowDetails] = useState(false);
  return (
    <div className='card mb-1'>
      <div
        className='card-body'
        style={{ cursor: 'pointer' }}
        onClick={() => toggleShowDetails(!showDetails)}
      >
        <div className='row'>
          <div className='col text-left'>
            <div className='small'>{formatDate(item.created_at)}</div>
            <p>{item.name}</p>
          </div>
          <div className='col text-right'>
            <div className='small'>{item.state}</div>
            <p>${item.amount}</p>
          </div>
        </div>
      </div>
      <div style={showDetails ? { display: 'block' } : { display: 'none' }}>
        <TransactionItemDetails transaction={item} />
      </div>
    </div>
  );
};
export default withRouter(memo(TransactionItem));
