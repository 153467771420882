import React, { Fragment } from 'react';
import apiService from '../../../lib/apiService';
import {
  sellerAccountEndpoint
  // API_ENDPOINT_USERS,
  // API_ENDPOINT_SELLER_ACCOUNT
} from '../../../lib/apiEndpoints';
import Loading from '../../../components/loading';
import Transactions from '../../../components/transactions';
import SellerAccount from './components/SellerAccount';
import SellerBankAccount from './components/SellerBankAccount';
import { openModal } from '../../../redux/actions/modalAction';
import { connect } from 'react-redux';

class SellerDashboard extends React.Component {
  //const { isSeller } = useContext(StateContext);
  constructor(props) {
    super(props);
    this.user_id = props.match.params.user_id;
    this.username = props.match.params.username;
    this.state = {
      loading: true,
      sellerAccount: {}
    };
  }

  componentDidMount() {
    this.toggleLoading(true);

    //Get Seller Details
    apiService
      .request('GET', sellerAccountEndpoint(this.user_id))
      .then(resp => {
        const { seller_account } = resp.data;

        this.setState({
          sellerAccount: { ...seller_account }
        });
      })
      .finally(() => this.toggleLoading());
  }
  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };
  onSellerCashout = () => {
    this.props.openModal({
      type: 'confirmation',
      size: 'medium',
      onConfirm: () => {
        apiService
          .request('PUT', sellerAccountEndpoint(this.user_id, 'cashout'))
          .then(resp => {
            this.setState({ sellerAccount: { ...resp.data.seller_account } });
          });
      }

      // onConfirm: () => {
      //   apiService
      //     .request(
      //       'PUT',
      //       `${API_ENDPOINT_USERS}/${
      //         this.user_id
      //       }/${API_ENDPOINT_SELLER_ACCOUNT}/cashout`
      //     )
      //     .then(resp => {
      //       this.setState({ sellerAccount: { ...resp.data.seller_account } });
      //     });
      // }
    });
  };
  onUpdateBankAccount = () => {
    this.props.openModal({
      type: 'custom',
      size: 'small',
      title: 'Update Bank Account',
      content: <SellerBankAccount />
    });
  };
  render() {
    const { loading, sellerAccount } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <div className='row'>
        <div className='col'>
          {!sellerAccount || !sellerAccount.profile ? (
            <div className='text-center'>
              <p>Register as Seller</p>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => this.props.history.push('/seller/register')}
              >
                Register
              </button>
            </div>
          ) : (
            <Fragment>
              <SellerAccount
                sellerAccount={sellerAccount}
                onSellerCashout={this.onSellerCashout}
                onUpdateBankAccount={this.onUpdateBankAccount}
              />
              <Transactions user_id={this.user_id} type={['sale', 'cashout']} />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { openModal }
)(SellerDashboard);
