import React from 'react';
// import apiService from '../lib/apiService';
import PropTypes from 'prop-types';
import LoadData from './LoadData';
class ListDataContainer extends React.Component {
  constructor(props) {
    super(props);
    this.paging = { page: 1, page_size: 10 };
    this.searchTerm = '';
    this.filterData = {};
    this.filterArray = [];
    this.sorting = {};
    this.state = {
      //data: [],
      //meta: { paging: { page: 1, page_size: 10 } },
      //paging: { page: 1, page_size: 10 },
      //searchTerm: '',
      //isLoading: false
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  prepareFetchData = (params = {}) => {
    console.log(this.sorting);

    this.searchTerm && Object.assign(params, { search: this.searchTerm });
    this.filterData && Object.assign(params, this.filterData);
    let checkSorting = this.sorting.direction ? true : false;
    if (checkSorting) {
      Object.assign(params, {
        sort:
          this.sorting.direction === 'desc'
            ? '-'
            : '' + this.sorting.column_name
      });
    }

    // this.filterData && Object.assign(params, );
    return params;
  };

  fetchDataCallback = () => {
    this.fetchData(this.prepareFetchData());
  };

  paginate = ({ page = 1, page_size = 10 }) => {
    this.paging = { page, page_size };
    this.fetchDataCallback();
    //this.setState({ paging: { page, page_size } }, this.fetchDataCallback);
  };
  onSearchChange = (value = '') => {
    this.paging = { page: 1, page_size: this.paging.page_size };
    this.searchTerm = value;
    this.fetchDataCallback();
  };
  filterChange = filterList => {
    console.log('**************88', filterList);

    this.filterData = [];
    filterList.forEach((column, key) => {
      if (column.value.length > 0) {
        this.filterData[`${column.name}`] = column.value[0].toLowerCase();
      }
    });

    this.fetchDataCallback();

    // Object.assign(params, { ...filterData });
  };
  // sortChange = () => {
  //   console.log('Sort');
  // };
  fetchData = (params = {}) => {
    this.props.fetchData({ ...this.paging, ...params });
  };

  onSortChange = (direction, column_name) => {
    this.sorting.direction = direction.trim();
    this.sorting.column_name = column_name.trim();

    // console.log('inside sort change function ', this.sorting);
    this.fetchDataCallback();
  };
  render() {
    const {
      isLoading,
      response: {
        data = [],
        meta = { paging: { ...this.paging, total_count: 0 } }
      }
    } = this.props;
    // console.log('inside list data', data, this.filterData);
    return this.props.render({
      sortChange: this.onSortChange,
      sorting: this.sorting,
      searchTerm: this.searchTerm,
      paging: this.paging,
      data,
      meta,
      filterData: this.filterData,
      isLoading,
      paginate: this.paginate,
      fetchData: this.fetchData,
      onSearchChange: this.onSearchChange,
      onFilterChange: this.filterChange
      // onSortChange: this.sortChange
    });
  }
}
const ListData = ({ endpoint, ...rest }) => {
  return (
    <LoadData
      endpoint={endpoint}
      method='GET'
      render={props => <ListDataContainer {...props} {...rest} />}
    />
  );
};
ListData.propTypes = {
  endpoint: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired
};

export default ListData;
