import React from 'react';
import { tabObject } from '../../../lib/helper';
import Tabs from '../../../components/tabs/Tabs';
import Feedback from './components/feedback';

const tabArray = [
  tabObject('All', false, {}),
  tabObject('Positive', false, { rating: 'positive' }),
  tabObject('Neutral', false, { rating: 'neutral' }),
  tabObject('Negative', false, { rating: 'negative' })
];

const SellerFeedback = props => {
  const { username } = props.match.params;
  return (
    <Tabs>
      {tabArray.map(tab => (
        <div key={tab.label} label={tab.label}>
          <Feedback {...props} username={username} {...tab.params} />
        </div>
      ))}
    </Tabs>
  );
};
export default SellerFeedback;
