import React from 'react';
import { ThumbUp, ThumbDown, ThumbsUpDown } from '@material-ui/icons';
import { formatDate } from '../../../../../lib/helper';
import seller_avatar from '../../../../../assets/images/defaultSellerAvatar.png';
import { Link } from 'react-router-dom';
import { userAccountUrl } from '../../../../../lib/linkCreatorService';

const FeedbackItem = ({ item }) => {
  const renderRatingIcon = rating => {
    if (rating === 'positive') {
      return <ThumbUp />;
    } else if (rating === 'negative') {
      return <ThumbDown />;
    }
    return <ThumbsUpDown />;
  };
  return (
    <div className='card mb-1'>
      <div className='card-body'>
        <div className='row '>
          <div className='col-2'>
            <Link
              to={userAccountUrl(item.reviewer._id, item.reviewer.username)}
            >
              <img
                className='img-thumbnail avatar-img'
                src={
                  item.reviewer.avatar ? item.reviewer.avatar : seller_avatar
                }
                alt='seller avatar'
              />
              {/* <img
                className='img-thumbnail avatar-img'
                //style={{ height: '50px', width: '50px' }}
                src={item.reviewer.avatar ? item.reviewer.avatar : ''}
                //src='https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg'
                alt={item.username}
              /> */}
            </Link>
          </div>
          <div className='col-10 '>
            <div className='float-left'>
              <div className={`review-type-${item.rating}`}>
                {renderRatingIcon(item.rating)}
                <Link
                  to={userAccountUrl(item.reviewer._id, item.reviewer.username)}
                >
                  <b className='reviewer_name'>{item.reviewer.username}</b>
                </Link>
              </div>
              <div>{item.message}</div>
            </div>
            <div className='float-right'>{formatDate(item.created_at)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackItem;
