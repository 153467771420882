import React, {useState,useEffect}from 'react';
import { Switch, Route } from 'react-router-dom';
import UserDetailsSidebar from './components/UserDetailsSidebar';
import Purchases from './purchases/Purchases';
import Favorites from './Favorites';
import BuyerSwaps from './BuyerSwaps';
import { Container } from '@material-ui/core';
import apiService from '../../../lib/apiService'
import { API_ENDPOINT_USERS } from '../../../lib/apiEndpoints';


const ViewContainer = props => {
  const { scope, username, user_id } = props.match.params;
  const [data,setData]=useState('')
  
  // const fetchUser = () => {
  //   setLoading(true)
  //   apiService
  //     .request(
  //       'GET',
  //       API_ENDPOINT_USERS + '/' + user_id
  //     )
  //     .then(resp => {
  //       setData(resp.data.user)
  //     })
  //     .finally(() =>setLoading(false));
  //     console.log(data)
  // };

  useEffect(() => {
    // Update the document title using the browser API
    // setLoading(true)
    apiService
      .request(
        'GET',
        API_ENDPOINT_USERS + '/' + user_id
      )
      .then(resp => {
        console.log('called')
        console.log(resp.data)
        setData({...resp.data.user})
      })
      // .finally(() =>setLoading(false));
  },[user_id]);

  return (
    <Container>
      <h2>User: @{username} email: {data.email||''}</h2>
      <div className='row'>
        <div className='col-2'>
          <UserDetailsSidebar scope={scope} username={username} user_id={user_id} />
        </div>
        <div className='col-10'>
          <Switch>
            {/* <Route exact path='/users/:user_id/:username/addresses' /> */}
            <Route exact path='/users/:user_id/:username/swaps' component={BuyerSwaps} />
            <Route exact path='/users/:user_id/:username/favorites' component={Favorites} />
            <Route path='/users/:user_id/:username/purchases' component={Purchases} />
            <Route exact path='/users/:user_id/:username' component={Purchases} />
          </Switch>
        </div>
      </div>
    </Container>
  );
};

export default ViewContainer;
