import queryString from 'query-string';
// import {
//   SWAP_ACTION_SHIPMENT,
//   SWAP_ACTION_RECEIVED,
//   SWAP_ACTION_SHIPPED,
//   SWAP_ACTION_COMPLETED
// } from '../models/swaps';
// import store from '../redux/store';
import {
  SWAP_ACTION_SHIPMENT,
  SWAP_ACTION_RECEIVED,
  SWAP_ACTION_SHIPPED,
  SWAP_ACTION_COMPLETED
} from '../models/swaps';
export const API_ENDPOINT_LOGIN = 'admin/user/login';
export const API_ENDPOINT_REGISTER = 'user/register';
export const API_ENDPOINT_USERS = 'users';
export const API_ENDPOINT_LOGOUT = 'logout';
export const API_ENDPOINT_PRODUCTS = 'items';
export const API_ENDPOINT_PRODUCT = 'items';
export const API_ENDPOINT_FACET_ITEMS = 'facet_items';
export const API_ENDPOINT_PRODUCT_DETAIL_FOR_EDIT = 'product-details-edit';
export const API_ENDPOINT_SAVE_PRODUCT = 'save-product';
export const API_ENDPOINT_CATEGORIES_LIST = 'categories-list';
export const API_ENDPOINT_CATEGORY_ATTRIBUTES = 'category-attributes';
export const API_ENDPOINT_UPLOAD_IMAGES = 'image';
export const API_ENDPOINT_SAVE_ADDRESS = 'save-address';
export const API_ENDPOINT_ADDRESS_LIST = 'addresses';
export const API_ENDPOINT_SELLER_ACCOUNT = 'seller-account';
//export const API_ENDPOINT_SELLER_PROFILE = 'seller-account/profile';
export const API_ENDPOINT_CREATE_SELLER = 'seller';
export const API_ENDPOINT_SWAPS='swaps';
export const API_ENDPOINT_NOTES = 'notes';
export const API_ENDPOINT_SHIPPING = 'shipping';
export const API_ENDPOINT_POST_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_GET_PAYMENT_METHODS = 'payment-methods';
export const API_ENDPOINT_DELETE_PAYMENT_METHOD = 'payment-methods';
export const API_ENDPOINT_CATEGORIES = 'categories';
export const API_ENDPOINT_FORGOTPASSWORD = 'forgot-password';
export const API_ENDPOINT_USERS_SETTING = 'users/settings';

export const itemsApiEndPoint = (itemId = '') => {
  return `${API_ENDPOINT_PRODUCT}${itemId ? `/${itemId}` : ''}`;
};
export const shippingQuoteForItem = (itemId, shipToAddressID) => {
  return `items/${itemId}/shipping?ship_to_address_id=${shipToAddressID}`;
};
export const swapOfferUpdateEndpoint = (swapId, offerId = '', type = '') => {
  if (
    type !== SWAP_ACTION_SHIPMENT &&
    type !== SWAP_ACTION_RECEIVED &&
    type !== SWAP_ACTION_SHIPPED &&
    type !== SWAP_ACTION_COMPLETED
  ) {
    return (
      swapsEndpoint(swapId) +
      `/offers${offerId ? '/' + offerId : ''}${type ? '/' + type : ''}`
    );
  }
  return swapsEndpoint(swapId) + `/${type}`;
  //return `swaps/${swapId}/offers${offerId ? '/' + offerId : ''}${type ? '/' + type : ''}`;
};
export const swapUpdateEndpoint = (swapId, state) => {
  return swapsEndpoint(swapId) + `/${state}`;
};
export const swapsEndpoint = swapId => {
  return `swaps${swapId ? '/' + swapId : ''}`;
};

export const swapsEndpoint2 = (swapId, type = '') =>
	`swaps${swapId ? `/${swapId}` : ''}${type ? `/${type}` : ''}`;

export const swapFeedbackEndpoint = swapId => {
  return swapsEndpoint(swapId) + '/feedback';
};
export const paymentMethodEndpoint = (methodId = '') => {
  return `payment-methods${methodId ? '/' + methodId : ''}`;
};
export const transactionsEndpoint = (type = []) => {
  return `transactions?${queryString.stringify(
    { type: type },
    { arrayFormat: 'bracket' }
  )}`;
};
export const sellerAccountEndpoint = (user_id, type = '') => {
  return `${API_ENDPOINT_USERS}/${user_id}/${API_ENDPOINT_SELLER_ACCOUNT}${
    type ? '/' + type : ''
  }`;
};
export const sellerAcountProfileEndpoint = () => {
  return sellerAccountEndpoint() + '/profile';
};

export const itemsListEndpoint = username => {
  return `${API_ENDPOINT_USERS}/${username}/items`;
};
export const itemsFavoritesEndpoint = itemId => {
  return `${API_ENDPOINT_PRODUCT}/${itemId}/favorite`;
};

export const userFeedbackEnpoint = username => {
  return `${API_ENDPOINT_USERS}/${username}/feedback`;
};
export const userTransactionsApiParams = (type = [], user_id) => {
  return { endpoint: `users/${user_id}/transactions`, params: { type: type } };
};
export const transactionsApiParams = (type = []) => {
  return { endpoint: `transactions`, params: { type: type } };
};
export const swapsListApiParams = (type, scope) => {
  return { endpoint: API_ENDPOINT_SWAPS, params: { type: type, scope: scope } };
};
export const itemSwapsListApiParams = itemId => {
  return { endpoint: API_ENDPOINT_SWAPS + `/item/${itemId}`, paras: {} };
};
export const itemsApiParams = (params = {}) => {
  return { endpoint: API_ENDPOINT_FACET_ITEMS, params: { ...params } };
};
export const userFavoritesApiParams = username => {
  return {
    endpoint: `${API_ENDPOINT_USERS}/${username}/favorites`,
    params: {}
  };
};
//export const transactionsEndpoint = ()
