import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';

export const BarCharts = ({
  listOfData,
  months,
  valuesOne,
  valuesTwo,
  colorOne,
  colorTwo,
  valuesThree,
  colorThree,
  width,
  height,
  valuesFour,
  colorFour,
}) => (
  <BarChart
    width={width}
    height={height}
    data={listOfData}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey={months} />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey={valuesOne} fill={colorOne} />
    <Bar dataKey={valuesTwo} fill={colorTwo} />
    <Bar dataKey={valuesThree} fill={colorThree} />
    <Bar dataKey={valuesFour} fill={colorFour} />
  </BarChart>
);

export const LineCharts = ({ listOfData, months, values }) => (
  <LineChart
    width={650}
    height={500}
    data={listOfData}
    margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey={months} />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line
      type="monotone"
      dataKey={values}
      stroke="#5650fb"
      activeDot={{ r: 8 }}
    />
  </LineChart>
);

export const PieCharts = ({ listOfData, dataIndex, values, colors }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#2F3030"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {listOfData[index].name} ({`${(percent * 100).toFixed(0)}%`})
      </text>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart width={600} height={400}>
        <Pie
          data={listOfData}
          cx={300}
          cy={200}
          labelLine
          isAnimationActive={false}
          label={renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          dataKey={values}
        >
          {listOfData
            ? listOfData.map((entry, index) => (
                <Cell key={index} fill={colors[index % colors.length]} />
              ))
            : '...loading'}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
