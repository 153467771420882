import React, { Fragment } from 'react';
import { BarCharts } from './Charts';
import DashboardBody from './Dashboard-body';

const DashboardTransactions = ({
  barData,
  selection,
  index,
  handleindexButton,
  handleChange,
  months,
  valuesOne,
  valuesTwo,
  colorOne,
  colorTwo,
  valuesThree,
  colorThree,
  colorFour,
  valuesFour,
  years,
}) => (
  <>
    <DashboardBody
      title="Transactions"
      data={years}
      selection={selection}
      index={index}
      handleindexButton={handleindexButton}
      handleChange={handleChange}
    >
      <BarCharts
        width={1200}
        height={500}
        listOfData={barData}
        months={months}
        valuesOne={valuesOne}
        colorOne={colorOne}
        valuesTwo={valuesTwo}
        colorTwo={colorTwo}
        valuesThree={valuesThree}
        colorThree={colorThree}
        colorFour={colorFour}
        valuesFour={valuesFour}
      />
    </DashboardBody>
  </>
);

export default DashboardTransactions;
