import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import apiService from '../../lib/apiService';
import DashboardUsers from './components/Dashboard_users';
import DashboardDailyUsers from './components/Dashboard_daily_users';
import DashboardTransactions from './components/Dashboard_transactions';
import DashboardDailyTransactions from './components/Dashboard_daily_transactions';
import Options from './components/Options';
import DashboardItems from './components/Dashboard_items';
import { storeData } from '../../redux/actions/dataActions';

const Dashboard = ({ data, storeData, location: { pathname } }) => {
  const option = [
    { name: 'users', route: '/dashboard' },
    { name: 'transactions', route: '/dashboard/transactions' },
    { name: 'items', route: '/dashboard/items' },
  ];
  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#c74375',
    '#98b4d4',
    '#372e29',
    '#dfddcd',
    '#e84855',
    '#bf1932',
  ];

  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [dataIndex, setDataIndex] = useState(0);
  const [dailyDataIndex, setDailyDataIndex] = useState(0);
  const [yearsArray, setYearsArray] = useState([]);
  const [monthsArr, setMonthsArr] = useState([]);
  const [refData, setRefData] = useState([]);
  const [refMonthlyData, setRefMonthlyData] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);
  const [dailyTransactions, setDailyTransactions] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [monthlyDailyData, setMonthlyDailyData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [viewSelection, setViewSelection] = useState('years');

  useEffect(() => {
    // setDashboardData(data)
    // if (dashboardData.length > 0) {
    //   dataFetch(context);
    // }
    console.log(data);
    if (data?.users) {
      setUsers(data.users);
      setTransactions(data.transactions);
      setYearsArray(data.yearsArray);
      setMonthsArr(data.monthsArr);
      setRefData(data.refData);
      setRefMonthlyData(data.refMonthlyData);
      setCompletedTransactions(data.completedTransactions);
      setDailyTransactions(data.dailyTransactions);
      setItemsData(data.itemsData);
      setMonthlyDailyData(data.monthlyDailyData);
      setUserData(data.userData);
    } else {
      apiReq();
    }
  }, []);

  useEffect(() => {
    getDailyUserData(users, yearsArray);
    getDailyTRansactionsData(transactions, yearsArray);
  }, [dataIndex]);

  useEffect(() => {
    console.log();
    if (
      users?.length &&
      transactions?.length &&
      yearsArray?.length &&
      refData?.length &&
      refMonthlyData?.length &&
      completedTransactions?.length &&
      dailyTransactions?.length &&
      itemsData?.length &&
      monthlyDailyData?.length &&
      userData?.length &&
      monthsArr?.length
    ) {
      storeData({
        users,
        transactions,
        yearsArray,
        refData,
        refMonthlyData,
        completedTransactions,
        dailyTransactions,
        itemsData,
        monthlyDailyData,
        userData,
        monthsArr,
      });
    }
  }, [
    users,
    transactions,
    yearsArray,
    refData,
    refMonthlyData,
    completedTransactions,
    dailyTransactions,
    itemsData,
    monthlyDailyData,
    userData,
    monthsArr,
  ]);

  // const dataFetch = (data) => {
  //   const years = Array.from(
  //     new Set(
  //       data[1].data
  //         .filter((user) => user.role === 'customer')
  //         .map((user) => moment(user.created_at).format('YYYY'))
  //     )
  //   );
  //   setYearsArray(years);
  //   setUsers(data[1].data);
  //   setTransactions(data[0].data);
  //   getRefData(data[1].data, years);
  //   getUserData(data[1].data, years);
  //   getTRansactionsData(data[0].data, years);
  //   getItemsData(data[2].data);
  //   getDailyUserData(data[1].data, years);
  //   getDailyTRansactionsData(data[0].data, years);

  // };

  const apiReq = async () => {
    try {
      const [
        transactionsApiCall,
        usersApiCall,
        itemsApiCall,
      ] = await Promise.all([
        apiService.request('GET', 'transactions', { type: ['sale'] }),
        apiService.request('GET', 'users'),
        apiService.request('GET', 'items'),
      ]);
      const years = Array.from(
        new Set(
          usersApiCall.data
            .filter((user) => user.role === 'customer')
            .map((user) => moment(user.created_at).format('YYYY'))
        )
      );
      setYearsArray(years);
      setUsers(usersApiCall.data);
      setTransactions(transactionsApiCall.data);

      getRefData(usersApiCall.data, years);
      getUserData(usersApiCall.data, years);
      getTRansactionsData(transactionsApiCall.data, years);
      getItemsData(itemsApiCall.data);
      getDailyUserData(usersApiCall.data, years);
      getDailyTRansactionsData(transactionsApiCall.data, years);
    } catch (err) {
      console.log(err);
    }
  };

  const getRefData = (usersData, years) => {
    const arrOfReferences = [];
    const monthlyRefDataArr = [];
    years.forEach((year) => {
      const info = usersData.filter(
        (user) => moment(user.created_at).format('YYYY') === year
      );
      const listOfMonthlyRefs = [];
      for (let i = 0; i <= 11; i++) {
        const listOfUsers = info.filter(
          (user) =>
            moment(user.created_at).format('MM') ===
            moment().month(i).format('MM')
        );
        const monthlyReferences = Array.from(
          new Set(listOfUsers.map((user) => user.reference))
        );
        if (monthlyReferences.length > 0) {
          monthlyReferences.forEach((ref) =>
            listOfMonthlyRefs.push({
              year,
              month: moment().month(i).format('M'),
              name: ref || 'N/A',
              value: listOfUsers.filter((user) => user.reference === ref)
                .length,
            })
          );
        } else {
          listOfMonthlyRefs.push([]);
        }
      }
      const reducedRefArr = listOfMonthlyRefs.reduce(
        (a, v) => ((a[v.month] = a[v.month] || []).push(v), a),
        []
      );
      monthlyRefDataArr.push(reducedRefArr);
      const references = new Set(info.map((user) => user.reference));
      const listOfRefs = [];
      references.forEach((ref) =>
        listOfRefs.push({
          name: ref || 'N/A',
          value: info.filter((user) => user.reference === ref).length,
          year,
        })
      );
      arrOfReferences.push(listOfRefs);
    });
    setRefData(arrOfReferences);
    setRefMonthlyData(monthlyRefDataArr);
  };

  const getUserData = (usersData, years) => {
    years.forEach((year) => {
      const userDataArr = [];
      const info = usersData.filter(
        (user) => moment(user.created_at).format('YYYY') === year
      );
      for (let i = 0; i <= 11; i++) {
        const listOfReferences = info
          .filter(
            (user) =>
              user.role === 'customer' &&
              moment(user.created_at).format('MM') ===
                moment().month(i).format('MM')
          )
          .map((user) => user.reference);
        let facebook;
        let instagram;
        let google;
        let discord;
        let text;
        let other;
        let craiglist;
        let youtube;
        let unavailable;
        facebook = instagram = google = discord = text = other = craiglist = youtube = unavailable = 0;
        listOfReferences.forEach((ref) => {
          switch (ref) {
            case 'Facebook':
              return (facebook += 1);
            case 'Instagram':
              return (instagram += 1);
            case 'Google Search':
              return (google += 1);
            case 'Discord':
              return (discord += 1);
            case 'Text Message':
              return (text += 1);
            case 'Other':
              return (other += 1);
            case 'Craiglist':
              return (craiglist += 1);
            case 'Youtube':
              return (youtube += 1);
            default:
              return (unavailable += 1);
          }
        });
        setMonthsArr((month) => [...month, moment().month(i).format('MMMM')]);
        const userInfo = {
          year,
          total_of_users: info.filter(
            (user) =>
              user.role === 'customer' &&
              moment(user.created_at).format('MM') ===
                moment().month(i).format('MM')
          ).length,
          month: moment().month(i).format('MMM'),
          Facebook: facebook,
          Instagram: instagram,
          Google_Search: google,
          Discord: discord,
          Text_Message: text,
          Craiglist: craiglist,
          Youtube: youtube,
          Other: other,
          Unavailable: unavailable,
        };
        userDataArr.push(userInfo);
      }
      setUserData((user) => [...user, userDataArr]);
    });
  };

  const getDailyUserData = (usersData, years) => {
    setMonthlyDailyData([]);
    const info = usersData.filter(
      (user) => moment(user.created_at).format('YYYY') === years[dataIndex]
    );
    const monthlyDataArr = [];
    for (let i = 0; i <= 11; i++) {
      const dailyDataArr = [];
      const daysInMonth = moment(`${years[dataIndex]}-${i + 1}`).daysInMonth();
      for (let j = 1; j <= daysInMonth; j++) {
        const users = info.filter(
          (user) =>
            user.role === 'customer' &&
            moment(user.created_at).format('MM') ===
              moment().month(i).format('MM') &&
            parseInt(moment(user.created_at).format('D')) === j
        );
        const refs = users.map((user) => user.reference);
        let facebook;
        let instagram;
        let google;
        let discord;
        let text;
        let other;
        let craiglist;
        let youtube;
        let unavailable;
        facebook = instagram = google = discord = text = other = craiglist = youtube = unavailable = 0;
        refs.forEach((ref) => {
          switch (ref) {
            case 'Facebook':
              return (facebook += 1);
            case 'Instagram':
              return (instagram += 1);
            case 'Google Search':
              return (google += 1);
            case 'Discord':
              return (discord += 1);
            case 'Text Message':
              return (text += 1);
            case 'Other':
              return (other += 1);
            case 'Craiglist':
              return (craiglist += 1);
            case 'Youtube':
              return (youtube += 1);
            default:
              return (unavailable += 1);
          }
        });
        const dailyData = {
          year: years[dataIndex],
          month: moment().month(i).format('MMMM'),
          day: j,
          total_of_users: users.length,
          Facebook: facebook,
          Instagram: instagram,
          Google_Search: google,
          Discord: discord,
          Text_Message: text,
          Craiglist: craiglist,
          Youtube: youtube,
          Other: other,
          Unavailable: unavailable,
        };
        dailyDataArr.push(dailyData);
      }
      monthlyDataArr.push(dailyDataArr);
    }
    setMonthlyDailyData((data) => [...data, monthlyDataArr]);
  };

  const getTRansactionsData = (data, years) => {
    years.forEach((year) => {
      const transactionDataArr = [];
      const completedTransactionList = data
        .filter(
          (transaction) =>
            moment(transaction.created_at).format('YYYY') === year
        )
        .filter((transaction) => transaction.state === 'completed');
      for (let i = 0; i <= 11; i++) {
        const transactions = completedTransactionList.filter(
          (transaction) =>
            moment(transaction.created_at).format('MM') ===
            moment().month(i).format('MM')
        );
        const subtotals = transactions.map(
          (transaction) => transaction.subtotals
        );
        let fees = 0;
        let totals = 0;
        let price = 0;
        subtotals.forEach((total) => {
          total.forEach((el) => {
            if (el.label.startsWith('Swap')) {
              fees += el.amount;
            } else if (el.label === 'Buyer Paid') {
              totals += el.amount;
            } else if (el.label === 'Sale Price') {
              price += el.amount;
            }
          });
        });
        const transactionDetail = {
          year,
          swap_fee: parseFloat(Math.abs(fees).toFixed(2)),
          buyer_paid: parseFloat(Math.abs(totals).toFixed(2)),
          sale_price: parseFloat(Math.abs(price).toFixed(2)),
          transactions: transactions.length,
          month: moment().month(i).format('MMM'),
        };
        transactionDataArr.push(transactionDetail);
      }
      setCompletedTransactions((transaction) => [
        ...transaction,
        transactionDataArr,
      ]);
    });
  };

  const getDailyTRansactionsData = (data, years) => {
    setDailyTransactions([]);
    const monthlyTransactionsArr = [];
    const completedTransactionList = data
      .filter(
        (transaction) =>
          moment(transaction.created_at).format('YYYY') === years[dataIndex]
      )
      .filter((transaction) => transaction.state === 'completed');
    for (let i = 0; i <= 11; i++) {
      const dailyTransactionsArr = [];
      const transactions = completedTransactionList.filter(
        (transaction) =>
          moment(transaction.created_at).format('MM') ===
          moment().month(i).format('MM')
      );
      const daysInMonth = moment(`${years[dataIndex]}-${i + 1}`).daysInMonth();
      for (let j = 1; j <= daysInMonth; j++) {
        const dailyTransactions = transactions.filter(
          (transaction) =>
            moment(transaction.created_at).format('MM') ===
              moment().month(i).format('MM') &&
            parseInt(moment(transaction.created_at).format('D')) === j
        );
        const subtotals = dailyTransactions.map(
          (transaction) => transaction.subtotals
        );
        let fees = 0;
        let totals = 0;
        let price = 0;
        subtotals.forEach((total) => {
          total.forEach((el) => {
            if (el.label.startsWith('Swap')) {
              fees += el.amount;
            } else if (el.label === 'Buyer Paid') {
              totals += el.amount;
            } else if (el.label === 'Sale Price') {
              price += el.amount;
            }
          });
        });
        const transactionDetail = {
          year: years[dataIndex],
          swap_fee: parseFloat(Math.abs(fees).toFixed(2)),
          buyer_paid: parseFloat(Math.abs(totals).toFixed(2)),
          sale_price: parseFloat(Math.abs(price).toFixed(2)),
          transactions: dailyTransactions.length,
          month: moment().month(i).format('MMMM'),
          day: j,
        };
        dailyTransactionsArr.push(transactionDetail);
      }
      monthlyTransactionsArr.push(dailyTransactionsArr);
    }
    setDailyTransactions((transactions) => [
      ...transactions,
      monthlyTransactionsArr,
    ]);
  };

  const getItemsData = (data) => {
    const listOfCategories = Array.from(
      new Set(data.map((item) => item.category.name))
    );
    listOfCategories.forEach((item) => {
      setItemsData((items) =>
        items.concat({
          item: data.filter(
            (items) =>
              items.category.name === item && items.state === 'available'
          ).length,
          category: item,
        })
      );
    });
  };

  const handledataIndexButton = (e) => {
    if (dataIndex > 0 && e.target.value === 'next') {
      setDataIndex(dataIndex - 1);
    } else if (
      dataIndex <= yearsArray.length - 2 &&
      e.target.value === 'previous'
    ) {
      setDataIndex(dataIndex + 1);
    }
  };

  const handleDailyDataIndex = (e) => {
    if (
      dailyDataIndex <= monthlyDailyData[0].length - 2 &&
      e.target.value === 'next'
    ) {
      setDailyDataIndex(dailyDataIndex + 1);
    } else if (dailyDataIndex > 0 && e.target.value === 'previous') {
      setDailyDataIndex(dailyDataIndex - 1);
    }
  };

  const handleChange = (e) => {
    const selection = e.target.value;
    setViewSelection(selection);
  };

  const usersComponent = () => (
    <DashboardUsers
      handleindexButton={handledataIndexButton}
      years={yearsArray}
      selection={viewSelection}
      lineData={userData[dataIndex]}
      pieData={refData[dataIndex]}
      index={dataIndex}
      months="month"
      lineValues="total_of_users"
      pieValues="value"
      colors={COLORS}
      facebook="Facebook"
      instagram="Instagram"
      discord="Discord"
      craiglist="Craiglist"
      youtube="Youtube"
      google="Google_Search"
      text="Text_Message"
      unavailable="Unavailable"
      other="Other"
      handleChange={handleChange}
    />
  );

  const transactionsComponent = () => (
    <DashboardTransactions
      selection={viewSelection}
      years={yearsArray}
      handleindexButton={handledataIndexButton}
      barData={completedTransactions[dataIndex]}
      index={dataIndex}
      months="month"
      valuesOne="transactions"
      valuesTwo="swap_fee"
      colorOne="#E7E918"
      colorTwo="#28E711"
      valuesThree="buyer_paid"
      colorThree="#E7180F"
      valuesFour="sale_price"
      colorFour="blue"
      handleChange={handleChange}
    />
  );

  const itemComponent = () => (
    <DashboardItems itemsData={itemsData} category="category" items="item" />
  );

  const dailyUsersComponent = () => (
    <DashboardDailyUsers
      selection={viewSelection}
      years={monthsArr}
      listOfData={monthlyDailyData[0][dailyDataIndex]}
      handleindexButton={handleDailyDataIndex}
      indexDaily={dailyDataIndex}
      days="day"
      totalUsers="total_of_users"
      facebook="Facebook"
      instagram="Instagram"
      discord="Discord"
      craiglist="Craiglist"
      youtube="Youtube"
      google="Google_Search"
      text="Text_Message"
      unavailable="Unavailable"
      other="Other"
      handleChange={handleChange}
      pieDataDaily={refMonthlyData[dataIndex][dailyDataIndex + 1]}
      pieValuesDaily="value"
      colorsDaily={COLORS}
    />
  );

  const dashboardDailyTransactions = () => (
    <DashboardDailyTransactions
      selection={viewSelection}
      years={monthsArr}
      handleindexButton={handleDailyDataIndex}
      dailyBarData={dailyTransactions[0][dailyDataIndex]}
      index={dailyDataIndex}
      months="day"
      valuesOne="transactions"
      valuesTwo="swap_fee"
      colorOne="#E7E918"
      colorTwo="#28E711"
      valuesThree="buyer_paid"
      colorThree="#E7180F"
      valuesFour="sale_price"
      colorFour="blue"
      handleChange={handleChange}
    />
  );

  const components = {
    '/dashboard':
      viewSelection === 'years' ? usersComponent() : dailyUsersComponent(),
    '/dashboard/transactions':
      viewSelection === 'years'
        ? transactionsComponent()
        : dashboardDailyTransactions(),
    '/dashboard/items': itemComponent(),
  };

  return (
    <div>
      <Options options={option} />
      {pathname === '/' ? components['/dashboard'] : components[pathname]}
    </div>
  );
};
const mapStateToProps = (state) => ({ data: state.data });
export default connect(mapStateToProps, { storeData })(Dashboard);
