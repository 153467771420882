import React from 'react';
import PropType from 'prop-types';
import { NavLink } from 'react-router-dom';
const SidebarMenu = ({ menuItems = [] }) => {
  return (
    <div className='nav flex-column nav-pills'>
      {menuItems.map((menuLink, index) => (
        <li key={index} className='nav-item'>
          <NavLink exact className='nav-link' to={menuLink.url}>
            {menuLink.title}
          </NavLink>
        </li>
      ))}
    </div>
  );
};
SidebarMenu.propTypes = {
  menuItems: PropType.array.isRequired
};
export default SidebarMenu;
