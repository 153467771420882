import React from 'react';

import FeedbackItem from './FeedbackItem';
import { compose } from 'redux';
import { userFeedbackEnpoint } from '../../../../../lib/apiEndpoints';
import ListHOC from '../../../../../components/ListHoc';

const FeedbackHOC = WrappedComponent => {
  const Container = props => {
    const params = props.rating ? { rating: [props.rating] } : {};
    return (
      <WrappedComponent
        {...props}
        apiParams={{
          endpoint: userFeedbackEnpoint(props.username),
          params: params
        }}
      />
    );
  };
  return Container;
};
const Feedback = compose(
  FeedbackHOC,
  ListHOC({ defaultMessage: 'No Feedback found', type: 'list' })
)(FeedbackItem);
export default Feedback;
