import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SellerDetailsSidebar from './components/sellerDetailsSidebar';
import SellerDashboard from './SellerDashboard';
import SellerListings from './SellerListings';
import SellerFeedback from './SellerFeedback';
import { Container } from '@material-ui/core';
import SellerSwaps from './SellerSwaps';

const SellerDetailsContainer = props => {
  const { scope, username, user_id } = props.match.params;
  return (
    <Container>
      <h2 className='mb-3'>Seller: @{username}</h2>
      <div className='row'>
        <div className='col-2'>
          <SellerDetailsSidebar scope={scope} username={username} user_id={user_id} />
        </div>
        <div className='col-10'>
          <Switch>
            {/* <Route exact path='/users/:user_id/:username/addresses' /> */}
            {/* <Route exact path='/users/:user_id/:username/swaps' component={}/> */}

            <Route path='/sellers/:user_id/:username/swaps' component={SellerSwaps} />
            <Route exact path='/sellers/:user_id/:username/listings' component={SellerListings} />
            <Route exact path='/sellers/:user_id/:username/feedback' component={SellerFeedback} />
            <Route path='/sellers/:user_id/:username/' component={SellerDashboard} />
          </Switch>
        </div>
      </div>
    </Container>
  );
};

export default SellerDetailsContainer;
