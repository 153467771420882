import { combineReducers } from 'redux';
import { toastsReducer } from './toastsReducer';
import { modalReducer } from './modalReducer';
import { userReducer } from './userReducer';
import { dataReducer } from './dataReducer';

export default combineReducers({
  // app: appReducer,
  toasts: toastsReducer,
  modals: modalReducer,
  user: userReducer,
  data: dataReducer,
});
