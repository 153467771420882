// import { CategoryDetails } from './categories';


export class ShippingDetails {
  weight?: number;
  weight_oz?: number;
  length?: number;
  width?: number;
  height?: number;
}
export class Product {
  name?: string = '';
  slug?: string = '';
  category_id?: any;
  stock_quantity?: number;
  enabled?: boolean;
  discontinued?: boolean;
  product_attributes?: ProductAttribute[] = [];
  regular_price?: number;
  on_sale?: boolean;
  variable?: boolean;
  price?: number;
  retail_price?: any;
  stock_status?: any;
  url?: string;
  path?: string;
  id?: any;
  _id?: any;
  state?: any;
  list_price?: number;
  favoriters_count?: number;
  created_at?: any;
  updated_at?: any;
  price_retail?: any;
  expired_at?: any;
  days_before_expires?: number;
  interested_count?: number;
  gtin?: any;
  gmc_shipping_cost?: any;
  quantity?: number;
  description?: string;
  images?: ProductImages[] = [];
  shipping_details?: ShippingDetails = new ShippingDetails();
  favorite?: boolean;
}
export class ProductAttribute {
  id?: any;
  name?: string;
  slug?: string;
  value?: string;
}
export class ProductImages {
  _id?: any;
  image_url?: string;
}


/*export class Product {
  name: string = "";
  slug: string = "";
  category_id?: any;
  stock_quantity?: number = 0;
  enabled?: boolean = true;
  discontinued?: boolean = false;
  product_attributes?: ProductAttribute[] = [];
  regular_price?: number;
  on_sale?: boolean;
  variable?: boolean;
  price?: number;
  list_price?: number;
  favoriters_count?: number;
  favorite?: false;
  created_at?: string;
  updated_at?: string;
  price_retail?: any;
  stock_status?: 'string';
  url?: string;
  path?: string;
  _id?: any;
  state?: string;
  description?: string;
  images?: ProductImages[] = [];
  shipping_details?: ShippingDetails = new ShippingDetails();
  expired_at?: any;
  days_before_expires?: number;
  interested_count?: number;
  gmc_shipping_cost?: any;
  gtin?: any;
  quantity?: number;
  categories?: CategoryDetails[] = [];
}
export class ProductAttribute {
  name?: string;
  slug?: string;
  value?: string;
}
export class ProductImages {
  id?: any;
  image_url?: string;
}
export class ShippingDetails {
  weight?: number;
  weight_oz?: number;
  length?: number;
  width?: number;
  height?: number;
}
*/
