export const getProductUrl = (id: number) => {
  return `/product/${id}`;
};
export const getShopUrl = () => {
  return `/products`;
};
export const getProductCategoyUrl = (id: number, params: {}) => {
  if (params) {
    let paramsArray = Object.entries(params).map((value, key) => {
      return `${key}=${value}`;
    });
    return `products/${id}/?${paramsArray.join('&')}`;
  }
  return `/products/${id}/`;
};
export const getProductUploadUrl = () => {
  return `/items/new`;
};
export const getLoginRegisterUrl = () => {
  return '/login-register';
};
export const userAccountUrl = (user_id: any, username: string, scope: string = '') => {
  return `/users/${user_id}/${username}`;
};
export const swapListPageUrl = (type: 'sell' | 'buy' = 'buy', scope: string = 'negotiations') => {
  return `/swaps/${type}/${scope}`;
};
export const checkOutpageUrl = (
  swapId: any,
  step: '',
  offerType: 'buy' | 'offer' = 'buy',
  offerAmount: ''
) => {
  const amountQuery = offerAmount ? `&offerAmount=${offerAmount}` : '';
  return `/swaps/${swapId}/checkout/${step}?offerType=${offerType}${amountQuery}`;
};
export const swapPageUrl = (swapId: any) => {
  return `/swaps/${swapId}`;
};
export const ItemSwaps = (itemId: any) => {
  return `/swaps/item/${itemId}`;
};
