import React, { Fragment } from 'react';
import { Grid, IconButton, Avatar } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  RemoveRedEye as ViewIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import TableList from '../../../../components/tableList';
import { API_ENDPOINT_PRODUCT } from '../../../../lib/apiEndpoints';
import apiService from '../../../../lib/apiService';
import { frontendAppUrl } from '../../../../lib/helper';

import { connect } from 'react-redux';
// import { openModal } from '../../../../redux/actions/modalAction';
import { openModal } from '../../../../redux/actions/modalAction';
// import ModalParams from '../../../../models/modal';
import ModalParams from '../../../../models/modal';
import NewTableList from '../../../../components/NewTableList';
import ListData from '../../../../components/ListData';
// import { debounce } from '../../../../lib/helper';
const ItemsListTable = withRouter(
  ({
    history,
    fetchData,
    data,
    props,
    onFilterChange,
    sorting,
    sortChange,
    // onSortChange,
    filterData,
    openModal,
    ...rest
  }) => {
    const onProducDeleteClicked = id => {
      let deleteModal = new ModalParams();
      deleteModal = {
        type: 'confirmation',
        confirmationText: 'Are you sure, you want to delete selected User ?',
        size: 'medium',
        showCloseBtn: true,
        onConfirm: () => {
          apiService
            .request('Delete', API_ENDPOINT_PRODUCT + '/' + id)
            .then(() => fetchData());
        }
      };
      openModal(deleteModal);
    };

    const columns = [
      {
        name: 'images',
        label: '',
        options: {
          filter: false,
          customerHeadRender: () => '',
          customBodyRender: value => {
            return value.length ? <Avatar src={value[0]['thumbnail']} /> : '';
          }
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          filterType: 'textField',
          customFilterListRender: v => `name: ${v}`,
          filterList: filterData.name ? [filterData.name] : ''
          // customBodyRender: value => {
          //   return <Link to={`/items/${value._id}`}>{value.username}</Link>;
          // }
        }
      },
      {
        name: 'seller',
        label: 'Seller',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData.seller ? [filterData.seller] : '',
          customFilterListRender: v => `seller: ${v}`,
          sort: false,
          customBodyRender: value => {
            if (!value) {
              return;
            }
            return (
              <Link to={`/sellers/${value._id}/${value.username}`}>
                {value.username}
              </Link>
            );
          }
        }
      },
      // {
      //   name: 'seller.username',
      //   label: 'Seller',
      //   options: {
      //     sort: false,
      //     display: false,
      //     filter: false,
      //     filterType: 'textField'

      //   }
      // },
      {
        name: 'state',
        label: 'State',
        options: {
          filter: true,
          filterOptions: {
            names: ['available', 'expired', 'sold', 'removed']
          },
          filterList: filterData.state ? [filterData.state] : ''
        }
      },
      {
        name: 'category',
        label: 'Category',
        customFilterListRender: v => `category: ${v}`,
        options: {
          filterType: 'textField',
          // filterOptions: {
          //   names: ['Available', 'Expited', 'Sold', 'Removed']
          // },
          filter: true,
          filterList: filterData.category ? [filterData.category] : '',
          display: true,
          customBodyRender: value => {
            if(!value){
              return;
            }
            return <Link to={`/categories/${value._id}`}>{value.name}</Link>;
          }
        }
      },
      {
        name: '_id',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Fragment>
                <IconButton
                  onClick={() =>
                    window.open(frontendAppUrl() + `/product/${value}`)
                  }
                >
                  <ViewIcon />
                </IconButton>
                <IconButton onClick={() => onProducDeleteClicked(value)}>
                  <DeleteIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      }
    ];

    columns.forEach(column => {
      if (column.name === sorting.column_name) {
        let sortDirection = sorting.direction ? sorting.direction : 'none';
        let tempCol = { ...column, sortDirection: sortDirection };
        column.options.options = { ...tempCol };
        // console.log(column);
      }
    });

    // console.log('columns after updates are ', columns);

    return (
      <NewTableList
        columns={columns}
        title='Products'
        fetchData={fetchData}
        data={data}
        filterData
        onFilterChange={onFilterChange}
        sortChange={sortChange}
        {...rest}
      />
    );
  }
);

const ItemsList = props => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <ListData
            endpoint='facet_items'
            render={value => <ItemsListTable {...value} {...props} />}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
ItemsListTable.propTypes = {
  apiParams: PropTypes.shape({
    endpoint: PropTypes.string,
    params: PropTypes.object
  }),
  endpoint: PropTypes.string,
  params: PropTypes.object
};
ItemsListTable.defaultProps = {
  //   endpoint: 'facet_items'
};
// export default withRouter(ItemsList);

export default withRouter(
  connect(
    null,
    { openModal }
  )(ItemsList)
);
