import React, { Fragment } from 'react';
const OrderSummary = ({ offer, transaction }) => {
  return (
    <div className='order-summary mt-3'>
      <h5 className='text-center'>{transaction ? 'Purchase Details' : 'Offer Details'}</h5>
      <table className='table'>
        <tbody>
          {transaction ? (
            <Fragment>
              {transaction.subtotals.map(subtotal => (
                <tr key={subtotal.label}>
                  <td>{subtotal.label}</td>
                  <td>${subtotal.amount}</td>
                </tr>
              ))}
              <tr>
                <td>Seller Earned</td>
                <td>
                  <b>${transaction.amount}</b>
                </td>
              </tr>
            </Fragment>
          ) : (
            <Fragment>
              <tr>
                <td>Offer Amount</td>
                <td>${offer.itemization.amount}</td>
              </tr>
              <tr>
                <td>Shipping</td>
                <td>${offer.itemization.shipping}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${offer.itemization.total}</td>
              </tr>
            </Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default OrderSummary;
