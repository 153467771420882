import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { swapsEndpoint } from '../../lib/apiEndpoints';

const TransactionItemDetails = ({ transaction }) => {
  return (
    <div className='p-3 bg-light'>
      {transaction.type === 'cashout' ? (
				<Fragment>
					{transaction.bank_account ? (
						<h5>
							{transaction.bank_account.bank_name} ****
							{transaction.bank_account.last4} {transaction.bank_account.currency}
						</h5>
					) : null}
					{transaction.paypal_email ? (
						<p className='text-bold'>Paypal Account: {transaction.paypal_email}</p>
					) : null}
					<p>{transaction.memo}</p>
				</Fragment>
			) : (
        <Fragment>
          <Link to={'/'+swapsEndpoint(transaction.swap_id)}>
            <b>Swap #{transaction.swap_id}</b>
          </Link>
          <div className='row'>
            <div className='col-1'>
              <Link to={swapsEndpoint(transaction.swap_id)}>
                {/* <img src={transaction.item.images[0].thumbnail} alt={transaction.name} /> */}
              </Link>
            </div>
            <div className='col-5 offset-6 text-left '>
              <div className='table-responsive'>
                <table className='table-borderless' style={{ width: '100%' }}>
                  <tbody>
                    {transaction.subtotals.map((subtotal, index) => (
                      <tr key={index}>
                        <td>{subtotal.label} </td>
                        <td>${subtotal.amount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <b>{transaction.type === 'sale' ? 'Seller Earned' : 'Buyer Paid'}</b>
                      </td>
                      <td>
                        <b>${transaction.amount}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default TransactionItemDetails;
