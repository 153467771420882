import React from 'react';
import {NavLink} from 'react-router-dom';
import {swapListPageUrl} from './linkCreatorService';

export const formatNumber=(number,settings) => {
	const x=3;
	const floatNumber=parseFloat(number||0)||0;

	const re=`\\d(?=(\\d{${x}})+${settings.decimal_number>0? '\\D':'$'})`;

	const num=floatNumber.toFixed(
		Math.max(0,Math.floor(settings.decimal_number))
	);

	return (settings.decimal_separator
		? num.replace('.',settings.decimal_separator)
		:num
	).replace(new RegExp(re,'g'),`$&${settings.thousand_separator}`);
};
export const formatDate=(isoDate,withTime=false) => {
	const date=new Date(Date.parse(isoDate));
	return (
		date.toLocaleString('en-us',{month: 'short'})+
		' '+
		date.getDate()+
		(withTime? `, ${date.getHours()}:${date.getMinutes()}`:'')
	);
};

export const formatEpocDate=(epocval,withTime=false) => {
	const date=new Date(Number(epocval));
	console.log(date)

	return (
		date.toLocaleString('en-us',{month: 'short'})+
		' '+
		date.getDate()+
		(withTime? `, ${date.getHours()}:${date.getMinutes()}`:'')
	);
};
const amountPattern='{amount}';
export const formatCurrency=(number=0,settings) =>
	settings.currency_format.replace(
		amountPattern,
		formatNumber(number,settings)
	);

export const getThumbnailUrl=(originalUrl,width) => {
	if(originalUrl&&originalUrl.length>0) {
		const pos=originalUrl.lastIndexOf('/');
		const thumbnailUrl=`${originalUrl.substring(
			0,
			pos
		)}/${width}/${originalUrl.substring(pos+1)}`;
		return thumbnailUrl;
	}
	return '';
};

export const getParentIds=(categories,id) => {
	let categoryId=id;
	const parentIds=[];
	let parentExists=false;

	do {
		const category=categories.find(item => item.id===categoryId);
		parentExists=category&&category.parent_id;
		if(parentExists) {
			parentIds.push(category.parent_id);
			categoryId=category.parent_id;
		}
	} while(parentExists);

	return parentIds;
};

export const getProductBreadcrumbs=(product,categories) => {
	if(product&&product.category_id) {
		const ids=[product.category_id];
		const parentIds=getParentIds(categories,product.category_id);
		ids.push(...parentIds);

		let index=0;
		const breadcrumbs=ids
			.reverse()
			.map(categoryId => {
				const category=categories.find(item => item.id===categoryId);
				if(category) {
					index+=1;
					return (
						<li key={index}>
							<NavLink to={category.path}>{category.name}</NavLink>
						</li>
					);
				}
				return null;
			})
			.filter(item => !!item);

		return breadcrumbs;
	}
	return null;
};

export const getCategoryBreadcrumbs=(currentCategoryId,categories) => {
	if(currentCategoryId) {
		const ids=getParentIds(categories,currentCategoryId);

		let index=0;
		const breadcrumbs=ids
			.reverse()
			.map(categoryId => {
				const category=categories.find(item => item.id===categoryId);
				if(category) {
					index+=1;
					return (
						<li key={index}>
							<NavLink to={category.path}>{category.name}</NavLink>
						</li>
					);
				}
				return null;
			})
			.filter(item => !!item);

		return breadcrumbs;
	}
	return null;
};

export const getShippingMethodFromOrder=(order,shippingMethods) => {
	if(
		order&&
		order.shipping_method_id&&
		shippingMethods&&
		shippingMethods.length>0
	) {
		return shippingMethods.find(
			method => method.id===order.shipping_method_id
		);
	}
	return null;
};

// export const getFieldLabelByKey = key => {
//   switch (key) {
//     case 'full_name':
//       return text.fullName;
//     case 'address1':
//       return text.address1;
//     case 'address2':
//       return text.address2;
//     case 'postal_code':
//       return text.postal_code;
//     case 'phone':
//       return text.phone;
//     case 'company':
//       return text.company;
//     case 'mobile':
//       return text.mobile;
//     case 'city':
//       return text.city;
//     case 'comments':
//       return text.comments;
//     default:
//       return '';
//   }
// };

// export const getShippingFieldLabel = ({ label, key }) =>
//   label && label.length > 0 ? label : getFieldLabelByKey(key);

// export const getCheckoutFieldLabel = ({ label, name }) =>
//   label && label.length > 0 ? label : getFieldLabelByKey(name);
export const getSwapsListNavigation=() => {
	return [
		{
			type: 'buy',
			label: 'Buying',
			link: swapListPageUrl('buy'),
			links: [
				{label: 'Negotiations',link: swapListPageUrl('buy','negotiations')},
				{label: 'Purchased',link: swapListPageUrl('buy','purchased')}
				// { label: 'Archived', link: swapListPageUrl('buy', 'archived') }
			]
		},
		{
			type: 'sell',
			label: 'Selling',
			link: swapListPageUrl('sell'),
			links: [
				{
					label: 'Negotiations',
					link: swapListPageUrl('sell','negotiations')
				},
				{label: 'Ship Now',link: swapListPageUrl('sell','pending_label')},
				{
					label: 'Pending Shipment',
					link: swapListPageUrl('sell','pending_shipment')
				},
				{label: 'Shipped',link: swapListPageUrl('sell','shipped')},
				{label: 'Completed',link: swapListPageUrl('sell','completed')}
				// { label: 'Archived', link: swapListPageUrl('sell', 'archived') }
			]
		}
	];
};
export const getSettingsNavigation=() => {
	return [
		{label: 'Seller Dashboard',link: '/seller'},
		{label: 'My Purchases',link: '/purchases'},
		{label: 'My Settings',link: '/settings/account'},
		// { label: 'My Notifications', link: '/settings/notifications' },
		{label: 'My Addresses',link: '/settings/addresses'},
		{label: 'My Payment Methods',link: '/settings/payment-methods'}
	];
};
export const getUserAccountNavigation=username => {
	return [
		{label: 'Items',link: `/account/${username}/items`},
		{label: 'Feedback',link: `/account/${username}/feedback`},
		{label: 'Favorites',link: `/account/${username}/favorites`}
		// {label: 'Followers', link:`/account/${username}/followers`},
		// {label: 'Following', link:`/account/${username}/following`},
	];
};
export const tabObject=(label,ownerAccessRequired,params={}) => {
	return {
		label: label,
		ownerAccessRequired: ownerAccessRequired,
		params: {...params}
	};
};

export function debounce(func,wait,immediate) {
	var timeout;

	return function() {
		var context=this,
			args=arguments;

		var later=function() {
			timeout=null;

			//func will be our fetchData function adn args will be arguments of fetchData function
			if(!immediate) func.apply(context,args);
		};

		var callNow=immediate&&!timeout;

		//hold the function execution setup by setTimeout
		clearTimeout(timeout);

		timeout=setTimeout(later,wait);
		if(callNow) func.apply(context,args);
	};
}
export function frontendAppUrl() {
	return process.env.REACT_APP_FRONTEND_URL;
}
//function to handle null values in buyer, seller and item
export const destructSwap=swap => {
	return {
		...swap,
		buyer: swap.buyer||{},
		seller: swap.seller||{},
		item: swap.item||{}
	};
};
//function to slugify a string
export const slugify=string => {
	const a=
		'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
	const b=
		'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p=new RegExp(a.split('').join('|'),'g');
	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g,'-') // Replace spaces with -
		.replace(p,c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g,'-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g,'') // Remove all non-word characters
		.replace(/\-\-+/g,'-') // Replace multiple - with single -
		.replace(/^-+/,'') // Trim - from start of text
		.replace(/-+$/,''); // Trim - from end of text
};
