import { STORE_DASHBOARD_DATA } from '../actionTypes';

export function dataReducer(state = {}, action) {
  switch (action.type) {
    case STORE_DASHBOARD_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
