export const IS_USER_LOGGEDIN = 'isUserLoggedIn';
export const USER_TOKEN = 'token';
export const USER_ID = 'user_id';
export const USER_NAME = 'name';
export const USER_EMAIL = 'email';
export const USER_AVATAR = 'avatar';
export const IS_SELLER = 'isSeller';
export class UserModel {
  [IS_USER_LOGGEDIN]: boolean = false;
  [USER_TOKEN]: any = '';
  [USER_ID]: any = '';
  [USER_NAME]: string;
  [USER_EMAIL]: string;
  [USER_AVATAR]: string;
  [IS_SELLER]: boolean;
}
export class User {
  _id?: any;
  email?: string;
  password?: string;
  role?: string;
  username?: string;
  avatar?: any;
  // following?: boolean;
  // name?: string;
}

export class userSettingData {
  emailAddr?: string;
  phone_number?: number;
  new_password?: string;
  password?: string;
}

export class AddressFlags {
  legal?: boolean = false;
  ship_from?: boolean = false;
  ship_to?: boolean = false;
}
export class Address {
  _id?: any;
  id?: any;
  first_name?: string = '';
  last_name?: string = '';
  street_1?: string = '';
  street_2?: string = '';
  city?: string = '';
  state?: string = '';
  country?: string = '';
  zip?: string = '';
  // ship_to?: boolean;
  // ship_from?: boolean;
  // legal?: boolean;
  flags?: AddressFlags = new AddressFlags();
}
export class DOB {
  day?: number;
  month?: number;
  year?: number;
}

export class SellerProfile {
  _id?: any;
  id?: any;
  first_name?: string = '';
  last_name?: string = '';
  mobile?: string = '';
  dob?: DOB = new DOB();
  //address?: Address = new Address();
}

export const ADDRESSES_SAMPLE: Address[] = [
  {
    _id: 411092,
    first_name: 'fname',
    last_name: 'asd',
    street_1: 'asd',
    street_2: '',
    city: 'new york',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: false,
    // ship_from: false,
    // legal: false
  },
  {
    _id: 411092332,
    first_name: 'fname',
    last_name: 'asd',
    street_1: 'asd',
    street_2: '',
    city: 'new york',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: false,
    // ship_from: false,
    // legal: false
  },
  {
    _id: 41102392,
    first_name: 'fname',
    last_name: 'asd',
    street_1: 'asd',
    street_2: '',
    city: 'new york',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: false,
    // ship_from: false,
    // legal: false
  },
  {
    _id: 407632,
    first_name: 'test',
    last_name: 'test',
    street_1: 'teasdasd',
    street_2: '',
    city: 'Newyork',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: true,
    // ship_from: true,
    // legal: true
  },
  {
    _id: 40237632,
    first_name: 'test',
    last_name: 'test',
    street_1: 'teasdasd',
    street_2: '',
    city: 'Newyork',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: true,
    // ship_from: true,
    // legal: true
  }
];
export const SELLER_ACCOUNT = {
  enabled: true,
  needs: [],
  profile: {
    type: 'individual',
    first_name: 'Joshua',
    last_name: 'Weber',
    business_name: null,
    dob: { year: 2000, month: 2, day: 24 },
    country: 'US',
    currency: 'usd',
    agree_tos: true,
    verified: true
  },
  earnings: { slug: 'total', name: 'Lifetime', amount: 2339.84, count: 36 },
  balance: { pending: 0.0, available: 0.0 },
  bank_account: {
    id: 19070,
    name: 'COMMUNITY FINANCIAL CREDIT UNION',
    last4: '7850',
    currency: 'usd',
    country: 'US'
  },
  ship_from_address: {
    id: 284725,
    first_name: 'Joshua',
    last_name: 'Weber',
    street_1: '447 Springfield Dr.',
    street_2: null,
    city: 'Canton',
    state: 'MI',
    zip: '48188',
    country: 'US',
    flags: { ship_to: true, ship_from: true, legal: true }
  },
  legal_address: {
    id: 284725,
    first_name: 'Joshua',
    last_name: 'Weber',
    street_1: '447 Springfield Dr.',
    street_2: null,
    city: 'Canton',
    state: 'MI',
    zip: '48188',
    country: 'US',
    flags: { ship_to: true, ship_from: true, legal: true }
  }
};
