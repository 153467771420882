import React, { Fragment } from 'react';
// import TableList from '../../../../components/tableList';
import {
  Delete as DeleteIcon,
  RemoveRedEye as ViewIcon
} from '@material-ui/icons';
import { IconButton, Grid } from '@material-ui/core';
import apiService from '../../../lib/apiService';
import { API_ENDPOINT_SELLER_ACCOUNT } from '../../../lib/apiEndpoints';
import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';
import NewTableList from '../../../components/NewTableList';
import ListData from '../../../components/ListData';
// import { debounce } from '../../../lib/helper';
const onSellerDeleteClicked = id => {
  apiService
    .request('Delete', API_ENDPOINT_SELLER_ACCOUNT + '/' + id)
    .then(() => {
      // this.forceUpdate();
    });
};
const SellersTable = withRouter(
  ({
    history,
    fetchData,
    data,
    params = {},
    onFilterChange,
    filterData,
    sorting,
    sortChange,
    ...rest
  }) => {
    const columns = [
      {
        name: 'username',
        label: 'Username',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData.username ? [filterData.username] : ''
        }
      },
      {
        name: 'seller_account.profile.first_name',
        label: 'First Name',
        options: {
          filter: true,
          filterType: 'textField',

          // customBodyRender: value => {
          //   return value ? value.profile.first_name : '';
          // },
          filterList: filterData['seller_account.profile.first_name']
            ? [filterData['seller_account.profile.first_name']]
            : ''
        }
      },
      {
        name: 'seller_account.profile.last_name',
        label: 'Last Name',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData['seller_account.profile.last_name']
            ? [filterData['seller_account.profile.last_name']]
            : ''
          // customBodyRender: value => {
          //   return value ? value.profile.last_name : '';
          // }
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: true,
          filterType: 'textField',
          filterList: filterData.email ? [filterData.email] : '',
          customBodyRender: value => {
            return value;
          }
        }
      },
      {
        name: 'seller_account.enabled',
        label: 'enabled',

        options: {
          customBodyRender: value => {
            return value ? 'YES' : 'NO';
          }
        }
      },
      {
        name: '_id',
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowData } = tableMeta;
            return (
              <Fragment>
                <IconButton
                  onClick={() =>
                    history.push(`/sellers/${value}/${rowData[0]}`)
                  }
                >
                  <ViewIcon />
                </IconButton>
                <IconButton onClick={() => onSellerDeleteClicked(value)}>
                  <DeleteIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      }
    ];

    columns.forEach(column => {
      if (column.name === sorting.column_name) {
        let sortDirection = sorting.direction ? sorting.direction : 'none';
        let tempCol = { ...column, sortDirection: sortDirection };
        column.options.options = { ...tempCol };
      }
    });

    // console.log('agfter updaion list is ', columns);

    return (
      <NewTableList
        columns={columns}
        title='Sellers'
        fetchData={fetchData}
        data={data}
        onFilterChange={onFilterChange}
        sortChange={sortChange}
        {...rest}
        filterData
        taleType='seller'
        // onFilterChange={onFilterChange}
      />
    );
  }
);

const SellersList = ({ history }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <ListData
            endpoint='sellers'
            render={value => <SellersTable {...value} />}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

// export default SellerListTable;
export default withRouter(SellersList);
