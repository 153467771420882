import React from 'react';
import apiService from '../../../../lib/apiService';
import { swapUpdateEndpoint } from '../../../../lib/apiEndpoints';
import { connect } from 'react-redux';
import { showErrorToast } from '../../../../redux/actions/toastsAction';
import { Formik, Form, Field } from 'formik';
const SwapShippedForm = ({ swap, reloadSwap, closeModal }) => {
	const submitForm = (values, actions) => {
		apiService
			.request('PUT', swapUpdateEndpoint(swap._id, 'shipped'), {
				reimbursement_amount: values.reimbursement_amount,
				tracking_url: values.tracking_url,
				carrier:
					values.carrier === 'other' ? values.custom_carrier : values.carrier,
				tracking_code: values.tracking_code,
				generate_tracker: values.generate_tracker
			})
			.then(resp => {
				if (reloadSwap) {
					reloadSwap();
				}
				if (closeModal) {
					closeModal();
				}
			})
			.finally(() => actions.setSubmitting(false));
	};

	const calculateDefaultAmount = () => {
		let current_offer = swap.offers.find(
			offer => offer._id === swap.current_offer_id
		);
		return current_offer['itemization']['shipping'];
	};
	const validateForm = values => {
		let errors = {};
		if (!values.carrier) {
			errors.carrier = 'Please select a carrier';
		}
		if (values.carrier === 'other' && values.custom_carrier === '') {
			errors.custom_carrier = 'Please specify a carrier';
		}
		if (!values.reimbursement_amount) {
			errors.reimbursement_amount = 'Please specify a reimbursement amount';
		}
		if (!values.tracking_code) {
			errors.tracking_code = 'Please specify a tracking code';
		}
		if (!values.generate_tracker && !values.tracking_url) {
			errors.tracking_url = 'Please specify a tracking URL';
		}
		return errors;
	};
	return (
		<>
			<Formik
				initialValues={{
					reimbursement_amount: calculateDefaultAmount(),
					carrier: '',
					custom_carrier: '',
					tracking_url: '',
					tracking_code: '',
					generate_tracker: true
				}}
				onSubmit={submitForm}
				validate={validateForm}>
				{({ errors, isSubmitting, isValid, values, touched }) => (
					<Form>
						<div className='form-group'>
							<label>Enter shipping amount to reimburse to seller</label>
							<sup> *</sup>
							<div className='input-group'>
								<div className='input-group-prepend'>
									<div className='input-group-text'>USD</div>
								</div>
								<Field
									type='tel'
									name='reimbursement_amount'
									placeholder='Reimbursement Amount'
									className='form-control col-4'
								/>
							</div>
							{errors.reimbursement_amount && touched.reimbursement_amount && (
								<small>{errors.reimbursement_amount}</small>
							)}
						</div>

						<div className='form-group'>
							<label htmlFor='exampleFormControlSelect1'>Carrier</label>
							<sup> *</sup>
							<Field
								name='carrier'
								component='select'
								className='form-control selector'
								id='exampleFormControlSelect1'>
								<option value=''>Select a carrier</option>
								<option value='USPS'>USPS</option>
								<option value='UPS'>UPS</option>
								<option value='FEDEX'>FEDEX</option>
								<option value='DHL'>DHL</option>
								<option value='other'>Other</option>
							</Field>
							{errors.carrier && touched.carrier && <small>{errors.carrier}</small>}
						</div>
						{values.carrier === 'other' ? (
							<div className='form-group'>
								{/* <label>Tracking URL</label> */}
								<Field
									type='text'
									className='form-control'
									name='custom_carrier'
									placeholder='Please specify a carrier'
								/>
								{errors.custom_carrier && touched.custom_carrier && (
									<small>{errors.custom_carrier}</small>
								)}
							</div>
						) : (
							''
						)}

						<div className='form-group'>
							<label>Tracking Code</label>
							<sup> *</sup>
							<Field
								name='tracking_code'
								type='text'
								className='form-control'
								placeholder='Tracking Code'
							/>
							{errors.tracking_code && touched.tracking_code && (
								<small>{errors.tracking_code}</small>
							)}
						</div>

						<div className='form-group form-check'>
							<Field name='generate_tracker'>
								{({ field, form, meta }) => (
									<>
										<input
											type='checkbox'
											className='form-check-input'
											id='exampleCheck1'
											checked={field.value}
											{...field}
										/>
										<label className='form-check-label' htmlFor='exampleCheck1'>
											Generate Tracker
										</label>
									</>
								)}
							</Field>
						</div>
						{(!values.generate_tracker && (
							<div className='form-group'>
								<label>Tracking URL</label>
								<Field
									type='text'
									className='form-control'
									name='tracking_url'
									placeholder='Enter Shipping Tracking URL'
								/>
								{errors.tracking_url && touched.tracking_url && (
									<small>{errors.tracking_url}</small>
								)}
							</div>
						)) ||
							null}
						<button
							type='submit'
							disabled={!isValid || isSubmitting}
							className='btn btn-primary'>
							Submit
						</button>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default connect(null, { showErrorToast })(SwapShippedForm);
