import React,{useEffect} from 'react';
import MessageItem from './messageItem';

const MessageList=({messages=[]}) => {
	const listRef=React.createRef();
	const scrollToBottom=() => {
		listRef.current.scrollTop=listRef.current.scrollHeight-listRef.current.offsetHeight;
	};
	useEffect(() => {
		if(listRef) {
			scrollToBottom();
		}
	},[messages.length]);
	return (
		<div className='message-list' ref={listRef}>
			<>
				{messages.map(message => (
					<MessageItem key={message._id} message={message} />
				))}
			</>
		</div>
	);
};

export default React.memo(MessageList);
