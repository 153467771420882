import React from 'react';
import Footer from './footer';
import MessageList from './messageList';
import '../../../assets/scss/messagePanel.scss';

const SwapMessagesPanel = ({ messages = [], sendMessage, sendingMessage }) => (
	<>
		<div className='card'>
			<div className='card-body'>
				<MessageList messages={[...messages]} />
				<Footer sendMessage={sendMessage} sendingMessage={sendingMessage} />
			</div>
		</div>
	</>
);
export default SwapMessagesPanel;
