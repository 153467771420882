import React, { useState,useContext } from 'react';
import {
  AppBar,
  Toolbar,
  makeStyles,
  createStyles,
  CssBaseline,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import DrawerMenu from './DrawerMenu';
import { withRouter } from 'react-router-dom';
import authService from '../../lib/auth';
const drawerWidth = 240;
const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar
  })
);
const Head=props => {
	
  const [open, toggleOpen] = useState(true);
  const classes = useStyles();
  const { location } = props;
  const pathname = location.pathname;
  if (pathname === '/login') {
    return null;
  }
  let title = 'PC Swaps Admin Dashboard';

  // const handleToggle = () => toggleOpen({ open: !this.state.open });
  const handleClose = () => toggleOpen({ open: false });

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <CssBaseline />
        <Toolbar>
          <Grid
            justify='space-between' // Add it here :)
            container
          >
            <Grid item>
              <Typography variant='h6' noWrap>
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button color='inherit' onClick={() => authService.signout()}>
              Logout
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerMenu
        width={drawerWidth}
        open={open}
        onClose={handleClose}
        currentUrl={pathname}
      />
    </div>
  );
};
export default withRouter(Head);
