import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { showErrorToast } from '../../../../redux/actions/toastsAction';
import { connect } from 'react-redux';
// import TransactionItemDetails from '../../../../components/transactions/TransactionItemDetails';
// import Transactions from '../../../../components/transactions';
// import SellerListings from '../SellerListings';

const SellerAccount = ({
  sellerAccount,
  onSellerCashout,
  onUpdateBankAccount,
  props
}) => {
  // const onCashoutBtnClicked = ()=>{
  //   openModal({
  //     type:'confirmation',
  //     size: 'medium',
  //     onConfirm: ()=>{
  //       apiService.request('PUT', sellerCashoutEndpoint()).then((resp)=>{
  //         onSellerCashout();
  //       })
  //     }
  //   })

  // }
  return (
    <Fragment>
      {/* {sellerAccount.earnings ? ( */}
      <div className='row'>
        <div className='col-5 text-center'>
          <div>
            <b>Total Earned</b>
          </div>
          <div>
            ${sellerAccount.earnings ? sellerAccount.earnings.total : 0}
          </div>
        </div>
        <div className='col-4 text-center'>
          <div>
            <b>Total Sales</b>
          </div>
          <div>{sellerAccount.earnings ? sellerAccount.earnings.count : 0}</div>
        </div>
        <div className='col-3'>
          <button
            className='btn btn-outline-primary btn-sm'
            onClick={onUpdateBankAccount}
          >
            Update Bank Account
          </button>
        </div>
      </div>
      {/* ) : (
        ''
      )} */}
      <hr />
      {/* {sellerAccount.balance ? ( */}
      <div className='row mb-3'>
        <div className='col-10'>
          <div>
            Available Balance: $
            {sellerAccount.balance ? sellerAccount.balance.available || 0 : 0}
          </div>
          <div>
            Pending Balance: $
            {sellerAccount.balance ? sellerAccount.balance.pending || 0 : 0}
          </div>
        </div>
        <div className='col-2 text-right'>
          <button
            className='btn btn-primary'
            disabled={
              !sellerAccount.balance ||
              !sellerAccount.balance.available ||
              sellerAccount.balance.available <= 0
            }
            //disabled={!sellerAccount.balance.available}
            onClick={onSellerCashout}
          >
            Cash Out
          </button>
        </div>
      </div>

      {/* <Transactions
        user_id='5d0b2159e0e6ae0a54002b95'
        type={['sale', 'cashout']}
      /> */}
    </Fragment>
  );
};

SellerAccount.propTypes = {
  sellerAccount: PropTypes.object,
  onSellerCashout: PropTypes.func
};

//export default SellerAccount;

export default connect(
  null,
  { showErrorToast }
)(SellerAccount);
