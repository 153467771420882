import { Product } from './product';
import { User, Address } from './user';

export class Transaction {
  _id?: any;
  type?: string;
  state?: string;
  label?: string;
  amount?: number;
  name?: string;
  memo?: any;
  //help_url: null,
  created_at?: string;
  subtotals?: TransactionSubtotal[] = [];
  swap_id?: any;
}

export class Tracker {
  slug?: string;
  name?: string;
  code?: string;
  url?: string;
}
export class SwapModel {
  _id?: any;
  state?: string;
  label?: string;
  has_unread?: boolean;
  current_offer_id?: any;
  updated_at?: any;
  archived?: boolean;
  can_archive?: false;
  tracking_url?: string;
  tracking_number?: any;
  print_url?: string;
  conversion_value?: number;
  created_at?: any;
  buyer?: User = new User();
  seller?: User = new User();
  item?: Product = new Product();
  offers?: Offer[] = [];
  ship_to_address?: Address = new Address();
  ship_from_address?: Address = new Address();
  transaction?: Transaction = new Transaction();
  tracker?: Tracker = new Tracker();
}

export class PriceDetails {
  price?: number;
  shipping?: number;
  total?: number;
}

export class Offer {
  _id?: any;
  state?: any;
  state_detailed?: string;
  quantity?: number;
  created_at?: any;
  updated_at?: any;
  expires_at?: any;
  itemization?: PriceDetails = new PriceDetails();
}

export class TransactionSubtotal {
  amount?: number;
  label?: string;
}

export const SWAP_NEW_STATE = 'new';
export const SWAP_CREATED_STATE = 'created';
export const SWAP_WITHDRAWN_STATE = 'withdrawn';
export const SWAP_MESSAGE_STATE = 'message';
export const SWAP_OFFER_STATE = 'offer';
export const SWAP_REJECTED_STATE = 'rejected';
export const SWAP_PROCESSING_STATE = 'processing';
export const SWAP_APPROVED_STATE = 'approved';
export const SWAP_PAYMENT_PENDING_STATE = 'payment-pending';
export const SWAP_SHIPMENT_STATE = 'shipment';
export const SWAP_SHIPPED_STATE = 'shipped';
export const SWAP_DECLINED_STATE = 'declined';
export const SWAP_ON_HOLD_STATE = 'on-hold';
export const SWAP_DELAYED_STATE = 'delayed';
export const SWAP_RECEIVED_STATE = 'received';
export const SWAP_DISPUTE_STATE = 'dispute';
export const SWAP_CANCELED_STATE = 'canceled';
export const SWAP_COMPLETED_STATE = 'completed';
export const SWAP_DISPUTED_STATE = 'dispute';

export const SWAP_ACTION_TRACK = 'track';
export const SWAP_ACTION_PRINT_LABEL = 'print_label';
export const SWAP_ACTION_SHIPMENT = 'shipment';
export const SWAP_ACTION_SHIPPED = 'shipped';
export const SWAP_ACTION_OFFER = 'offer';
export const SWAP_ACTION_BUY = 'buy';
export const SWAP_ACTION_ACCEPT_OFFER = 'accepted';
export const SWAP_ACTION_REJECT_OFFER = 'rejected';
export const SWAP_ACTION_WITHDRAW = 'withdrawn';
export const SWAP_ACTION_RECEIVED = 'received';
export const SWAP_ACTION_ACCEPT_REJECT = 'accept_reject';
export const SWAP_ACTION_COMPLETED = 'completed';
export const SWAP_ACTION_CANCELED = 'canceled';
export const SWAP_ACTION_DELAYED = 'delayed';
export const SWAP_ACTION_FEEDBACK = 'feedback';

export const SWAP: SwapModel = {
  _id: 1313369,
  state: 'new',
  label: 'Pending Shipment',
  has_unread: false,
  current_offer_id: 629325,
  updated_at: '2019-05-10T01:34:56.000-04:00',
  archived: false,
  can_archive: false,
  tracking_url:
    'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=9400136205903833779939',
  tracking_number: '9400136205903833779939',
  print_url:
    'https://sidelineswap.com/print?s=1313369\u0026l=https://easypost-files.s3-us-west-2.amazonaws.com/files/postage_label/20190510/9af7df5cf8f7475fb31b9c0562bba3e9.png\u0026item_id=827204',
  conversion_value: 2.0,
  created_at: '2019-05-09T10:10:34.000-04:00',
  buyer: {
    _id: 385549,
    username: 'ashwin1089',
    avatar: null
    //following: false
  },
  seller: {
    _id: 352127,
    username: 'neo1089',
    avatar: null
    //following: false
  },
  item: {
    _id: 827204,
    state: 'sold',
    name: 'Nike',
    price: 1.0,
    list_price: 1.0,
    url: 'https://sidelineswap.com/gear/baseball/apparel/827204-nike',
    favoriters_count: 0,
    favorite: false,
    created_at: '2019-05-09T10:08:07.000-04:00',
    updated_at: '2019-05-10T01:33:40.000-04:00',
    price_retail: '',
    description: '',
    product_attributes: [
      {
        slug: 'brand',
        name: 'Brand',
        value: 'nike'
      },
      {
        slug: 'apparel-type',
        name: 'Apparel Type',
        value: 'jackets'
      },
      {
        slug: 'condition',
        name: 'Condition',
        value: 'used'
      }
    ],
    expired_at: '2020-05-08T10:08:45.000-04:00',
    days_before_expires: 364.36,
    interested_count: 2,
    gtin: null,
    gmc_shipping_cost: '15.0',
    quantity: 0,
    // categories: [
    //   {
    //     id: 110058,
    //     slug: 'baseball/apparel',
    //     name: 'Apparel',
    //     full_name: 'Baseball \u003e Apparel',
    //     url: 'https://sidelineswap.com/shop/baseball/apparel/l148'
    //   }
    // ],
    images: [
      {
        _id: 2880564,
        image_url:
          'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_large.jpeg'
      }
    ]
    //sale: null
  },
  offers: [
    {
      _id: 628407,
      state: 'rejected',
      state_detailed: 'rejected_by_seller',
      quantity: 1,
      created_at: '2019-05-09T10:24:16.000-04:00',
      updated_at: '2019-05-09T10:29:24.000-04:00',
      expires_at: null,
      itemization: { price: 1.0, shipping: 6.09, total: 7.09 }
    },
    {
      _id: 628415,
      state: 'rejected',
      state_detailed: 'rejected_by_buyer',
      quantity: 1,
      created_at: '2019-05-09T10:34:09.000-04:00',
      updated_at: '2019-05-09T10:36:17.000-04:00',
      expires_at: null,
      itemization: { price: 2.0, shipping: 6.09, total: 8.09 }
    },
    {
      _id: 629312,
      state: 'rejected',
      state_detailed: 'rejected_by_seller',
      quantity: 1,
      created_at: '2019-05-10T01:19:45.000-04:00',
      updated_at: '2019-05-10T01:31:19.000-04:00',
      expires_at: null,
      itemization: { price: 2.0, shipping: 6.09, total: 8.09 }
    },
    {
      _id: 629325,
      state: 'accepted',
      state_detailed: 'accepted_by_seller',
      quantity: 1,
      created_at: '2019-05-10T01:33:04.000-04:00',
      updated_at: '2019-05-10T01:33:36.000-04:00',
      expires_at: null,
      itemization: { price: 2.0, shipping: 6.09, total: 8.09 }
    }
  ],

  ship_to_address: {
    _id: 408083,
    first_name: 'test',
    last_name: 'test',
    street_1: 'asdad',
    street_2: 'as',
    city: 'Newyork',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: true,
    // ship_from: false,
    // legal: false
  },
  ship_from_address: {
    _id: 407632,
    first_name: 'test',
    last_name: 'test',
    street_1: 'teasdasd',
    street_2: '',
    city: 'Newyork',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: false,
    // ship_from: true,
    // legal: false
  },
  transaction: {
    _id: 624112,
    type: 'purchase',
    state: 'pending',
    label: 'Pending',
    amount: 8.09,
    name: 'Nike',
    memo: null,
    created_at: '2019-05-10T01:33:39.000-04:00',
    subtotals: [
      { amount: 2.0, label: 'Item Price' },
      { amount: 6.09, label: 'Shipping' }
    ],
    swap_id: 1313369
  },
  tracker: {
    slug: 'usps',
    name: 'USPS',
    code: '9400136205903833779939',
    url:
      'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=9400136205903833779939'
  }
};
export const SHIP_TO_ADDRESSES: Address[] = [
  {
    _id: 408083,
    first_name: 'test',
    last_name: 'test',
    street_1: 'asdad',
    street_2: 'as',
    city: 'Newyork',
    state: 'NY',
    zip: '12345',
    country: 'US',
    flags: {
      legal: false,
      ship_from: false,
      ship_to: false
    }
    // ship_to: true,
    // ship_from: false,
    // legal: false
  }
];
export const SWAP_LIST: SwapModel[] = [
  {
    _id: 1323783,
    state: 'offer',
    label: 'Offer Withdrawn',
    has_unread: false,
    current_offer_id: 633844,
    updated_at: '2019-05-14T08:17:31.000-04:00',
    buyer: {
      _id: 385549,
      username: 'ashwin1089',
      avatar: null
      //following: false
    },
    seller: {
      _id: 352127,
      username: 'neo1089',
      avatar: null
      //following: false
    },
    item: {
      _id: 833574,
      state: 'available',
      name: 'Nike',
      price: 1.0,
      list_price: 1.0,
      url: 'https://sidelineswap.com/gear/baseball/apparel/833574-nike',
      favoriters_count: 0,
      favorite: false,
      created_at: '2019-05-13T06:21:37.000-04:00',
      updated_at: '2019-05-13T16:04:58.000-04:00',
      images: [
        {
          _id: 2880564,
          image_url:
            'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg'
          // thumb_url:
          //   'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg',
          // small_url:
          //   'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_small.jpeg',
          // large_url:
          //   'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_large.jpeg'
        }
      ]
    },
    offers: [
      {
        _id: 632647,
        state: 'rejected',
        state_detailed: 'rejected_by_buyer',
        quantity: 1,
        created_at: '2019-05-13T06:28:08.000-04:00',
        updated_at: '2019-05-13T06:36:15.000-04:00',
        expires_at: null,
        itemization: { price: 1.5, shipping: 6.17, total: 7.67 }
      },
      {
        _id: 633844,
        state: 'rejected',
        state_detailed: 'rejected_by_buyer',
        quantity: 1,
        created_at: '2019-05-14T07:34:49.000-04:00',
        updated_at: '2019-05-14T08:13:18.000-04:00',
        expires_at: null,
        itemization: { price: 2.0, shipping: 6.17, total: 8.17 }
      }
    ]
  },
  {
    _id: 1313520,
    state: 'message',
    label: 'Message',
    has_unread: false,
    current_offer_id: null,
    updated_at: '2019-05-09T11:25:46.000-04:00',
    buyer: {
      _id: 162103,
      username: 'lcballgloves',
      avatar: null
      // following: false
    },
    seller: {
      _id: 352127,
      username: 'neo1089',
      avatar: null
      //following: false
    },
    item: {
      _id: 827204,
      state: 'sold',
      name: 'Nike',
      price: 1.0,
      list_price: 1.0,
      url: 'https://sidelineswap.com/gear/baseball/apparel/827204-nike',
      favoriters_count: 0,
      favorite: false,
      created_at: '2019-05-09T10:08:07.000-04:00',
      updated_at: '2019-05-10T01:33:40.000-04:00',
      images: [
        {
          _id: 2880564,
          image_url:
            'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg'
        }
      ]
    },
    offers: []
  }
];

/*
const testSwaplist = {
  data: [
    {
      id: 1323783,
      state: 'offer',
      label: 'Offer Withdrawn',
      has_unread: false,
      current_offer_id: 633844,
      updated_at: '2019-05-14T08:17:31.000-04:00',
      buyer: {
        id: 385549,
        username: 'ashwin1089',
        avatar_url: null,
        following: false,
        emblems: []
      },
      seller: {
        id: 352127,
        username: 'neo1089',
        avatar_url: null,
        following: false,
        emblems: ['us']
      },
      item: {
        id: 833574,
        state: 'available',
        name: 'Nike',
        category_1: 'baseball',
        category_2: 'apparel',
        price: 1.0,
        list_price: 1.0,
        url: 'https://sidelineswap.com/gear/baseball/apparel/833574-nike',
        favoriters_count: 0,
        favorite: false,
        sync_gmc: false,
        created_at: '2019-05-13T06:21:37.000-04:00',
        updated_at: '2019-05-13T16:04:58.000-04:00',
        label: null,
        actions: [
          'show',
          'edit',
          'duplicate',
          'remove',
          'view_swaps',
          'list_similar'
        ],
        images: [
          {
            id: 2880564,
            thumb_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg',
            small_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_small.jpeg',
            large_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_large.jpeg'
          }
        ]
      },
      offers: [
        {
          id: 632647,
          state: 'rejected',
          state_detailed: 'rejected_by_buyer',
          quantity: 1,
          created_at: '2019-05-13T06:28:08.000-04:00',
          updated_at: '2019-05-13T06:36:15.000-04:00',
          expires_at: null,
          itemization: { price: 1.5, shipping: 6.17, total: 7.67 },
          variation: null
        },
        {
          id: 633844,
          state: 'rejected',
          state_detailed: 'rejected_by_buyer',
          quantity: 1,
          created_at: '2019-05-14T07:34:49.000-04:00',
          updated_at: '2019-05-14T08:13:18.000-04:00',
          expires_at: null,
          itemization: { price: 2.0, shipping: 6.17, total: 8.17 },
          variation: null
        }
      ],
      messages: [
        {
          id: 8147354,
          body: 'test',
          type: 'Swaps::Messages::BaseMessage',
          tone: 'message',
          buyer_read: true,
          seller_read: true,
          offer_id: null,
          created_at: '2019-05-14T08:17:31.000-04:00',
          sender: {
            id: 385549,
            username: 'ashwin1089',
            avatar_url: null,
            following: false,
            emblems: []
          }
        }
      ],
      actions: [
        { slug: 'send_message', label: 'Send Message' },
        { slug: 'add_images', label: 'Add Additional Images' },
        { slug: 'mark_as_unread', label: 'Mark as Unread' },
        { slug: 'archive', label: 'Archive' },
        { slug: 'report', label: 'Report' }
      ]
    },
    {
      id: 1313520,
      state: 'message',
      label: 'Message',
      has_unread: false,
      current_offer_id: null,
      updated_at: '2019-05-09T11:25:46.000-04:00',
      buyer: {
        id: 162103,
        username: 'lcballgloves',
        avatar_url: null,
        following: false,
        emblems: ['us']
      },
      seller: {
        id: 352127,
        username: 'neo1089',
        avatar_url: null,
        following: false,
        emblems: ['us']
      },
      item: {
        id: 827204,
        state: 'sold',
        name: 'Nike',
        category_1: 'baseball',
        category_2: 'apparel',
        price: 1.0,
        list_price: 1.0,
        url: 'https://sidelineswap.com/gear/baseball/apparel/827204-nike',
        favoriters_count: 0,
        favorite: false,
        sync_gmc: false,
        sold_via_offer: true,
        created_at: '2019-05-09T10:08:07.000-04:00',
        updated_at: '2019-05-10T01:33:40.000-04:00',
        label: { text: 'SOLD', color: '#f44141' },
        actions: ['show', 'duplicate', 'view_swaps', 'list_similar'],
        images: [
          {
            id: 2880564,
            thumb_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg',
            small_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_small.jpeg',
            large_url:
              'https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_large.jpeg'
          }
        ]
      },
      offers: [],
      messages: [
        {
          id: 8045506,
          body: 'What is this?',
          type: 'Swaps::Messages::BaseMessage',
          tone: 'message',
          buyer_read: true,
          seller_read: true,
          offer_id: null,
          created_at: '2019-05-09T11:25:46.000-04:00',
          sender: {
            id: 162103,
            username: 'lcballgloves',
            avatar_url: null,
            following: false,
            emblems: ['us']
          }
        }
      ],
      actions: [
        { slug: 'send_message', label: 'Send Message' },
        { slug: 'mark_as_unread', label: 'Mark as Unread' },
        { slug: 'archive', label: 'Archive' },
        { slug: 'report', label: 'Report' }
      ]
    }
  ],
  meta: {
    paging: {
      total_pages: 1,
      total_count: 2,
      page_size: 50,
      page: 1,
      has_next_page: false
    },
    total_pages: 1,
    total_count: 2,
    page_size: 50,
    page: 1,
    has_next_page: false
  }
};
*/
