import React from 'react';
import { Container } from '@material-ui/core';
import SaleTransactions from './components/SaleTransactions';

const TransactionsList = () => {
  return (
    <Container>
      <SaleTransactions />
    </Container>
  );
};

export default TransactionsList;
