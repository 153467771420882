import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  // Delete as DeleteIcon,
  RemoveRedEye as ViewIcon
} from '@material-ui/icons';
import { Avatar, IconButton, Grid } from '@material-ui/core';
// import { RemoveRedEye } from '@material-ui/icons';
import TableList from '../../../../components/tableList';
// import apiService from '../../../../lib/apiService';
// import { API_ENDPOINT_SWAPS } from '../../../../lib/apiEndpoints';
import { formatDate } from '../../../../lib/helper';
// import defaultItemAvatar from '../../../assets/images/default_item_avatar.png';

const onFilterChange = value => {
  console.log('values coming from tableList to swaplist is ', value);
};
const SwapsListTable = ({ history, params = {} }) => {
  const swapTableRef = useRef();

  // const onSwapDeleteClicked = id => {
  //   apiService.request('Delete', API_ENDPOINT_SWAPS + '/' + id).then(() => {
  //     swapTableRef.current.fetchData();
  //     // this.forceUpdate();
  //   });
  // };

  const columns = [
    {
      name: '_id',
      label: 'Swap #',
      options: {
        filter: true,
        filterType: 'textField'
      }
    },
    {
      name: 'item',
      label: 'Item',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          return (
            <>
              <div>
                <div>
                  <Link to={`/items`}>
                    <Avatar src={value.images[0]['thumbnail']} />
                  </Link>
                </div>
                <div>
                  <Link to={`/items`}> {value.name}</Link>
                </div>
              </div>
            </>
          );
        }
      }
    },
    {
      name: 'buyer',
      label: 'Buyer',
      options: {
        //   filter: false,
        //   sort: false,
        customBodyRender: value => {
          if (!value) {
            return;
          }
          return (
            <Link to={`/users/${value._id}/${value.username}`}>
              {value.username}
            </Link>
          );
        }
      }
    },
    {
      name: 'seller',
      label: 'Seller',
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (!value) {
            return;
          }
          return (
            <Link to={`/sellers/${value._id}/${value.username}`}>
              {value.username}
            </Link>
          );
        }
      }
    },

    {
      name: 'buyer.username',
      label: 'Buyer',
      options: {
        sort: false,
        display: false,
        filter: true,
        filterType: 'multiselect',
        customFilterListRender: v => `Seller: ${v}`
      }
    },
    {
      name: 'label',
      label: 'State',
      options: {
        customFilterListRender: v => `state: ${v}`
      }
    },
    {
      name: 'created_at',
      label: 'Created At',
      options: {
        customBodyRender: value => {
          return formatDate(value);
        }
      }
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Fragment>
              <IconButton onClick={() => history.push(`/swaps/${value}`)}>
                <ViewIcon />
              </IconButton>
              {/* <IconButton onClick={() => onSwapDeleteClicked(value)}>
                <DeleteIcon />
              </IconButton> */}
            </Fragment>
          );
        }
      }
    }
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableList
          ref={swapTableRef}
          title='Swaps'
          columns={columns}
          apiParams={{ endpoint: 'swaps', params: params }}
          onFilterChange={onFilterChange}
        />
      </Grid>
    </Grid>
  );
};
SwapsListTable.propTypes = {
  params: PropTypes.object
};
export default withRouter(SwapsListTable);
