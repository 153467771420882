import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import apiService from '../../../lib/apiService';
import CategoriesDropdown from '../edit/component/CategoriesDropdown';
import FileUpload from '../../../components/FileUpload';
import CategoryAttributes from '../edit/component/CategoryAttributes';
import { API_ENDPOINT_CATEGORIES } from '../../../lib/apiEndpoints';
import { showSuccessToast } from '../../../redux/actions/toastsAction';
import { connect } from 'react-redux';
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { slugify } from '../../../lib/helper';


class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.isNew = this.props.match.params.category_id === 'create';
    this.state = {
      category: {},
      loading: false,
      categoryImage: '',
      categoryImageFile: {},
      parentCategory: {}
    };
  }
  componentDidMount() {
    const { category_id } = this.props.match.params;
    if (category_id !== 'create') {
      this.fetchCategory();
    }
  }
  fetchCategory() {
    this.toggleLoading(true);
    apiService
      .request('GET', `categories/${this.props.match.params.category_id}`)
      .then(resp => {
        this.setState({
          category: resp.data.category,
          categoryImage:
            resp.data.category.image && resp.data.category.image.image_url
        });
      })
      .finally(() => this.toggleLoading());
  }
  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };
  handleImputChange = e => {
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      category: { ...this.state.category, [e.target.name]: value }
    });
  };
  handleInputChange = (e) => {
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const item = {
      ...this.state.item,
      [e.target.name]:
        e.target.type === 'number'
          ? parseFloat(e.target.value)
          : e.target.value,
    };
    this.setState({
      category: { ...this.state.category, [e.target.name]: value }
    });
  };
  onParentCategoryChange = category => {
    this.setState({
      category: { ...this.state.category, parent: category._id },
      parentCategory: { ...category }
    });

    // console.log('parent category inside cat component', category);
  };

  // getBaseCategory = value => {
  //   this.setState({ singleCategory: { ...value } });
  //   this.setState({ category: { ...this.state.category, parent: value._id } });
  //   console.log('single category inseide parent component is ', value);
  // };

  onImageUpload = (file, imageSrc) => {
    this.setState({
      categoryImage: imageSrc,
      categoryImageFile: { file: file, imageSrc: imageSrc }
    });
  };
  onImageRemoveClick = imageSrc => {
    this.setState({
      categoryImage: '',
      categoryImageFile: {},
      category: { ...this.state.category, image: {} }
    });
  };
  onAttributesChange = attributes => {
    this.setState({
      category: { ...this.state.category, category_attributes: [...attributes] }
    });
  };
  onSave = async () => {
    const { categoryImageFile } = this.state;
    if (Object.keys(categoryImageFile).length > 0) {
      let formData = new FormData();
      formData.append('image[]', categoryImageFile.file);
      const images = await apiService.uploadImage(formData);
      this.setState(
        {
          category: { ...this.state.category, image: { ...images[0] } }
        },
        () => this.prepareCategoryData()
      );
    } else {
      this.prepareCategoryData();
    }
  };

  prepareCategoryData = () => {
    let { slug, name,dimension_width,dimension_height,dimension_length,dimension_weigth } = { ...this.state.category };
    let category = { ...this.state.category };

    if (
      category.category_attributes &&
      category.category_attributes.length > 0
    ) {
      let category_attributes = [...category.category_attributes];
      //mutuation issue; need to check!
      // let categoryAttributeList = category_attributes.map(value => {
      // 	value.slug = value.slug ? slugify(value.slug) : slugify(value.name);
      // 	return value;
      // });
      category = { ...category, category_attributes: [...category_attributes] };
    }

    if (!slug) {
      category.slug = slugify(name);
    }
   
    this.setState(
      {
        category: {
          ...category
        }
      },
      this.postFormData
    );
  };
  postFormData() {
    apiService
      .request(
        this.isNew ? 'POST' : 'PUT',
        // `${API_ENDPOINT_CATEGORIES}/${this.isNew ?'':`/${this.state.category._id}`,
        `${API_ENDPOINT_CATEGORIES}${this.isNew ? '' : `/${this.state.category._id}`
        }`,

        this.state.category
      )
      .then(resp => {
        this.props.showSuccessToast('Category Inserted Successfully');
        //this.props.history.push(`/categories/${resp.data._id}`);
        this.props.history.push(`/categories`);
      });
  }
  render() {
    const { category } = this.state;
    console.log(category)
    return (
      <>
        <h2>{this.isNew ? 'Create New Category' : 'Edit Category'}</h2>
        <form>
          <div className='card category-details mb-3'>
            <div className='card-body '>
              <div className='card-title'>
                <h3>Category Details</h3>
              </div>
              <div className='form-group'>
                <label>
                  Name <sup>*</sup>
                </label>
                <input
                  type='text'
                  required
                  name='name'
                  className='form-control'
                  value={category.name || ''}
                  onChange={this.handleImputChange}
                />
              </div>
              <div className='form-group'>
                <label>
                  Slug <sup>*</sup>
                </label>
                <input
                  type='text'
                  name='slug'
                  className='form-control'
                  value={category.slug || ''}
                  onChange={this.handleImputChange}
                />
              </div>

              <FormGroup>
                <FormControlLabel
                  //labelPlacement='start'
                  control={
                    <Switch
                      name='featured'
                      checked={category.featured ? true : false}
                      onChange={this.handleImputChange}
                      value={true}
                    />
                  }
                  label='Featured Category'
                />
              </FormGroup>
              <div className='form-group'>
                <CategoriesDropdown
                  title='Parent Category'
                  category_id={category.parent || ''}
                  handleCategoryChange={this.onParentCategoryChange}
                  getBaseCategory={this.getBaseCategory}
                />
              </div>
              <br></br>
              <div className='form-group'>
                <label>
                  Suggested Shipping Dimensions 
                </label>
                <br></br> 
                <div className="form-row">
                <div className="form-group col">
                <label htmlFor="product-price">Width</label>
                    <input
                       type='number'
                       name='dimension_width'
                       className='form-control'
                       value={category.dimension_width || ''}
                       onChange={this.handleImputChange}
                    />
                  </div>
                  <div className="col-sm">
                    <label htmlFor="product-retail-price">
                      Height
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="dimension_height"
                      onChange={this.handleImputChange}
                      pattern="[0-9]+"
                      value={category.dimension_height || ''}
                    />
                    </div>
                    <div className="col-sm">
                    <label htmlFor="product-retail-price">
                      Length
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="dimension_length"
                      onChange={this.handleImputChange}
                      pattern="[0-9]+"
                      value={category.dimension_length || ''}
                    />
                    </div>
                    <div className="col-sm">
                    <label htmlFor="product-retail-price">
                      Weight
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="dimension_weigth"
                      onChange={this.handleImputChange}
                      pattern="[0-9]+"
                      value={category.dimension_weigth || ''}
                    />
                    </div>
                  
                    </div>
                    </div>
              <div className='form-row'>
                <div className='form-group col-3'>
                  <label>Category Image</label>
                  <FileUpload
                    imageSrc={
                      this.state.categoryImage && this.state.categoryImage
                    }
                    onUpload={this.onImageUpload}
                    onRemoveClick={this.onImageRemoveClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card category-attributes mb-3'>
            <div className='card-body'>
              <div className='card-title'>
                <h3>Attributes</h3>
              </div>
              <CategoryAttributes
                attributes={category.category_attributes}
                onChange={this.onAttributesChange}
              />
            </div>
          </div>
          <button
            type='button'
            onClick={this.onSave}
            className='btn btn-primary'
          >
            Save
          </button>
        </form>
      </>
    );
  }
}
// export default withRouter(Connect()CategoryForm);
export default withRouter(
  connect(
    null,
    { showSuccessToast }
  )(CategoryForm)
);
