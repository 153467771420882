import React, { Fragment } from 'react';
import apiService from '../lib/apiService';
import Loading from '../components/loading';
import Pagination from './Pagination';
const isEqual = require('react-fast-compare');
const ListHOC = ({
  endpoint = '',
  params = {},
  defaultMessage = 'List is Empty',
  type = 'list',
  sortByList = []
}) => WrappedComponent =>
    class List extends React.PureComponent {
      constructor(props) {
        super(props);
        this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
        this.pageSize = 20;
        this.state = {
          data: [],
          paging: {},
          loading: true,
          currentPage: 1
        };
      }
      toggleLoading = (flag = false) => {
        this.setState({ loading: flag });
      };
      componentDidMount() {
        this.fetchData();
      }
      componentDidUpdate(prevProps) {
        if (!isEqual(this.props.apiParams, prevProps.apiParams)) {
          this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
          this.fetchData();
        }
      }
      fetchData = (pageParams = { page: 1, page_size: this.pageSize }) => {
        this.toggleLoading(true);
        apiService
          .request('GET', endpoint || this.apiParams.endpoint, {
            ...params,
            ...this.apiParams.params,
            ...pageParams
          })
          .then(resp => {
            console.log(resp)
            this.setState({
              data: [...resp.data],
              paging: { ...resp.meta.paging }
            });
          })
          .catch(err => console.log(err))
          .finally(() => this.toggleLoading());
      };
      render() {
        const { loading, data, paging } = this.state;

        if (!loading && data.length === 0) {
          return <p className='text-center'>{defaultMessage}</p>;
        }
        const items = data.map(item => (
          <WrappedComponent
            key={item._id}
            item={{ ...item }}
            {...this.props}
            reloadList={() => this.fetchData()}
          />
        ));
        return (
          <Fragment>
            {loading ? (
              <Loading />
            ) : type === 'grid' ? (
              <div className='row'>{items}</div>
            ) : (
                  items
                )}
            {data.length > 0 ? (
              <Pagination
                {...paging}
                // totalRecords={paging.total_count}
                // pageLimit={paging.page_size}
                pageNeighbours={3}
                //currentPage={paging.page}
                onPageChanged={pageData =>
                  this.fetchData({
                    page: pageData.page,
                    page_size: pageData.page_size
                  })
                }
              />
            ) : (
                ''
              )}

            <div className='d-flex justify-content-end' />
          </Fragment>
        );
      }
    };

export default ListHOC;
