import { useState } from 'react';
import PropTypes from 'prop-types';
import apiService from '../lib/apiService';

const LoadData = ({ render, method = 'GET', endpoint }) => {
  const [isLoading, toggleLoading] = useState(false);
  const [response, setResponse] = useState({});
  const fetchData = params => {
    toggleLoading(true);
    apiService
      .request(method, endpoint, { ...params })
      .then(resp => {
        setResponse({ ...resp });
      })
      .catch(err => console.log(err))
      .finally(() => toggleLoading(false));
  };
  return render({ response, isLoading, fetchData });
};

LoadData.propTypes = {
  render: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired
};

export default LoadData;
