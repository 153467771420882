import React from 'react';


const Footer = ({ sendMessage, sendingMessage }) => {
  const messageInputRef = React.createRef();
  const onSendBtnClicked = () => {
    let { value } = messageInputRef.current;
    if (!value.trim()) {
      return;
    }
    // logic to scan message and then send message

    if (value) {
      const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-])/g;
      const phoneRegex = /([\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6})/g;
      value = value.replace(emailRegex, '***************');
      value = value.replace(phoneRegex, '***************');
      sendMessage(value.trim());
    }

    messageInputRef.current.value = '';
    document.getElementById('text').style.height = '';
  };
  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    if (e.key === 'Enter') {
      e.preventDefault();
      onSendBtnClicked();
    }
  };

  return (
    <div className="row mt-4">
      <div className="col-md-10">
        <textarea
          placeholder="Send a Message..."
          type="text"
          ref={messageInputRef}
          className="form-control message-input"
          id="text"
          onKeyDown={handleKeyDown}
          tabIndex="0"
          rows="1"
          // onChange={handleResize}
        />
      </div>
      <div className="col-md-2 mt-2 mt-md-0">
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          disabled={sendingMessage}
          onClick={onSendBtnClicked}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Footer;
