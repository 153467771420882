import React from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import Loading from '../../../components/loading';

const dasboardBody = ({
  children,
  title,
  data,
  index,
  handleindexButton,
  handleChange,
  selection,
}) => (
  <div>
    <div className="mb-3">
      <h1 className="text-center mb-3">{title}</h1>
      <div className="row">
        <div className="col-sm-3 text-right">
          <button
            style={{ paddingTop: '10px' }}
            value="previous"
            onClick={handleindexButton}
            className="btn"
          >
            Previous
          </button>
        </div>
        <div className="col-sm-3">
          <h3
            style={{ paddingTop: '10px', fontWeight: 'bolder' }}
            className="float-right"
          >
            {data?.length > 0 ? data[index] : <Loading />}
          </h3>
        </div>
        <div className="col-sm-3">
          <FormControl style={{ minWidth: '120px' }} className="float-left">
            <InputLabel>Select view</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selection}
              onChange={handleChange}
            >
              <MenuItem value="years" default>
                Years
              </MenuItem>
              <MenuItem value="months">Months</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-sm-3 text-left">
          <button
            style={{ paddingTop: '10px' }}
            value="next"
            onClick={handleindexButton}
            className="btn"
          >
            Next
          </button>
        </div>
      </div>
      {/* <h3 className="text-center d-flex justify-content-around">
                    <button disabled={data.length > 0 && index < data.length - 1 ? false : true} value="previous" onClick={handleindexButton} className="btn">Previous</button>
                    {data.length > 0 ? data[index] : <Loading />}
                    <FormControl style={{ minWidth: "120px" }}>
                        <InputLabel>Select view</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={time}
                            onChange={handleChange}>
                            <MenuItem value={0} default>Years</MenuItem>
                            <MenuItem value={1}>Months</MenuItem>
                        </Select>
                    </FormControl>
                    <button disabled={index > 0 ? false : true} value="next" onClick={handleindexButton} className="btn">Next</button>
                </h3> */}
    </div>
    <div className="row">{children}</div>
  </div>
);

export default dasboardBody;
