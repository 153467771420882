
import React from 'react'
import FooterLayout from './footerLayout'
import DisplayNotes from './displayNotes'


export default function NotePanel({notes,addNote,deleteNote}) {
	console.log(notes,addNote)
	//  deleteNote={deleteNote} note-card
	return (
		<div className='card col-lg-6'  >
			<div className='card-body'>
				<DisplayNotes messages={[...notes]} deleteNote={deleteNote}/>
				<hr></hr>
				<FooterLayout addNote={addNote}   />
			</div>

		</div>
	)
}
