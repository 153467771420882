import React, { Fragment, useRef } from 'react';
import { Grid, IconButton, Avatar } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  RemoveRedEye as ViewIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableList from '../../../../components/tableList';
import { API_ENDPOINT_PRODUCT } from '../../../../lib/apiEndpoints';
import apiService from '../../../../lib/apiService';
import { frontendAppUrl } from '../../../../lib/helper';

import { connect } from 'react-redux';
import { openModal } from '../../../../redux/actions/modalAction';
import ModalParams from '../../../../models/modal';

const ItemListTable = ({
  history,
  endpoint,
  params = {},
  apiParams,
  openModal,
  filterData
}) => {
  const itemTableRef = useRef();

  const onProducDeleteClicked = id => {
    let deleteModal = new ModalParams();
    deleteModal = {
      type: 'confirmation',
      confirmationText: 'Are you sure, you want to delete selected Item ?',
      size: 'medium',
      showCloseBtn: true,
      onConfirm: () => {
        apiService
          .request('Delete', API_ENDPOINT_PRODUCT + '/' + id)
          .then(() => itemTableRef.current.fetchData());
      }
    };
    openModal(deleteModal);
    // apiService.request('Delete', API_ENDPOINT_PRODUCT + '/' + id).then(() => {
    //   itemTableRef.current.fetchData();
    // this.forceUpdate();
    // });
  };

  const columns = [
    {
      name: 'images',
      label: '',
      options: {
        filter: false,
        customerHeadRender: () => '',
        customBodyRender: value => {
          return value.length ? <Avatar src={value[0]['thumbnail']} /> : '';
        }
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        filterType: 'textField',
        filterList: filterData?filterData.attribute_name:'',
        customFilterListRender: v => `Name: ${v}`,
        options: {
          // filterType: 'textField',
          customBodyRender: value => {
            return <Link to={`/items/${value._id}`}>{value.username}</Link>;
          }
        }
      }
    },
    {
      name: 'seller',
      label: 'Seller',
      options: {
        filter: false,
        customFilterListRender: v => `seller: ${v}`,
        sort: false,
        customBodyRender: value => {
          if (!value) {
            return;
          }
          return (
            <Link to={`/sellers/${value._id}/${value.username}`}>
              {value.username}
            </Link>
          );
        }
      }
    },
    {
      name: 'seller.username',
      label: 'Seller',
      options: {
        sort: false,
        display: false,
        filter: true,
        filterType: 'multiselect'
        // filterOptions: {
        //   names: ['a', 'test', 'test1']
        // }
      }
    },
    { name: 'state', label: 'State' },
    {
      name: 'category',
      label: 'Category',
      customFilterListRender: v => `category: ${v}`,
      options: {
        filter: false,
        display: false,
        customBodyRender: value => {
          return <Link to={`/categories/${value._id}`}>{value.name}</Link>;
        }
      }
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fragment>
              <IconButton
                onClick={() =>
                  window.open(frontendAppUrl() + `/product/${value}`)
                }
              >
                <ViewIcon />
              </IconButton>
              <IconButton onClick={() => onProducDeleteClicked(value)}>
                <DeleteIcon />
              </IconButton>
            </Fragment>
          );
        }
      }
    }
  ];
  console.log('process.env.REACT_APP_FRONTEND_URL');
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <TableList
            ref={itemTableRef}
            title='Products'
            columns={columns}
            apiParams={
              apiParams ? apiParams : { endpoint: endpoint, params: params }
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
ItemListTable.propTypes = {
  apiParams: PropTypes.shape({
    endpoint: PropTypes.string,
    params: PropTypes.object
  }),
  endpoint: PropTypes.string,
  params: PropTypes.object
};
ItemListTable.defaultProps = {
  endpoint: 'facet_items'
};
// export default withRouter(ItemListTable);

export default withRouter(
  connect(
    null,
    { openModal }
  )(ItemListTable)
);
