import React from "react";
import {formatDate} from "../../../lib/helper";
import {Link} from "react-router-dom";
import store from '../../../redux/store';

const MessageItem=({message}) => {
	const user=store.getState().user;

	console.log(user)
	console.log(message)
	const messageFrom=user.user_id!==message.sender._id? "theirs":"mine";
	return (
		<>
			<div className={`swap-message-container ${messageFrom}`}>
				<div>
					{messageFrom==="theirs"&&(
						<div className="slsfb swap-message-username-row slsfb_aic">
							<Link
								className="swap-message--username"
								to={`/users/${message.sender._id}/${message.sender.username}`}
							>
								{message.sender.username}
							</Link>
						</div>
					)}
					<pre
						className={`text-break swap-message swap-message--${messageFrom} ${message.tone&&`swap-message--${message.tone}`
							}`}
					>
						{message.body}
					</pre>

					<p className={`message-time message-time--${messageFrom}`}>
						<small>{formatDate(message.created_at,true)}</small>
					</p>
				</div>
				{/* <a className={`swap-message--avatar ${messageFrom}`}>
          <img src='https://assets.sidelineswap.com/web/assets/d9926c9c391c33f752d1f3b6c945bf14.png' />
        </a> */}
			</div>
		</>
	);
};

export default MessageItem;


