import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { PieCharts } from './Charts';
import DashboardBody from './Dashboard-body';

const DashboardUsers = ({
  handleChange,
  lineData,
  selection,
  pieData,
  handleindexButton,
  index,
  months,
  lineValues,
  pieValues,
  colors,
  years,
  facebook,
  instagram,
  google,
  discord,
  text,
  other,
  craiglist,
  youtube,
  unavailable,
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 0);
  }, []);

  return (
    <div>
      {loaded ? (
        <DashboardBody
          title="Users"
          data={years}
          selection={selection}
          index={index}
          handleindexButton={handleindexButton}
          handleChange={handleChange}
        >
          <div className="col-sm-6">
            <LineChart
              width={650}
              height={500}
              data={lineData}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={months} />
              <YAxis />
              <Tooltip itemSorter={(item) => -item.value} filterNull />
              <Legend margin={{ top: 100 }} />
              <Line
                type="monotone"
                dataKey={lineValues}
                stroke="#0088FE"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={facebook}
                stroke="#00C49F"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={instagram}
                stroke="#FFBB28"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={discord}
                stroke="#FF8042"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={youtube}
                stroke="#c74375"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={google}
                stroke="#98b4d4"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={text}
                stroke="#372e29"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={other}
                stroke="#dfddcd"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={unavailable}
                stroke="#e84855"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey={craiglist}
                stroke="#bf1932"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </div>
          <div className="col-sm-6">
            <PieCharts
              listOfData={pieData}
              dataIndex={index}
              values={pieValues}
              colors={colors}
            />
          </div>
        </DashboardBody>
      ) : null}
    </div>
  );
};
export default DashboardUsers;
