import React, { Fragment } from 'react';
import apiService from '../../lib/apiService';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { debounce } from '../../lib/helper';

const isEqual = require('react-fast-compare');

class TableList extends React.PureComponent {
	formattedKeysArray = [];
	formattedValueArray = [];
	constructor(props) {
		super(props);
		this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
		//this.pageSize = 5;
		//this.currentPage = 1;

		this.state = {
			data: [],
			dataKeys: [],
			paging: { page: 1, page_size: 10 },
			loading: true,
			//currentPage: 1,
			params: this.apiParams.params,
			searchTerm: ''
		};
	}
	toggleLoading = (flag = false) => {
		this.setState({ loading: flag });
	};

	componentDidMount() {
		this.fetchData();
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(this.props.apiParams, prevProps.apiParams)) {
			this.apiParams = this.props.apiParams || { endpoint: '', params: {} };
			this.fetchData();
		}
	}
	fetchData = (pageParams = { ...this.state.paging }) => {
		this.toggleLoading(true);
		apiService
			.request('GET', this.apiParams.endpoint, {
				...pageParams,
				...this.state.params
			})
			.then(resp => {
				this.setState({
					data: [...resp.data],
					paging: { ...resp.meta.paging }
				});
			})
			.catch(err => console.log(err))
			.finally(() => this.toggleLoading());
	};

	onTableChange = (action, tableState) => {
		switch (action) {
			case 'changePage':
				this.fetchData({
					page: tableState.page + 1,
					page_size: tableState.rowsPerPage
				});
				break;
			case 'changeRowsPerPage':
				this.fetchData({ page: 1, page_size: tableState.rowsPerPage });
				break;
			case 'filterChange':
				let filterList = tableState.columns.map((column, key) => {
					return {
						name: column.name,
						value: tableState.filterList[key] ? tableState.filterList[key] : []
					};
				});
				// console.log('exists on filetr change', this.props.onFilterChange);
				this.props.onFilterChange && this.props.onFilterChange(filterList);
				break;
			default:
				break;
		}
	};

	onSearchChange = value => {
		this.setState(
			{
				searchTerm: value,
				params: { ...this.state.params, search: value }
			},
			this.fetchData
		);
	};

	onSearchClose = () => {
		this.setState({ searchTerm: null });
		this.setState({ params: {} });
		setTimeout(this.fetchData, 600);
		//this.fetchData();
	};

	onSortColumChnage = () => {};
	render() {
		const { data, paging, loading, searchTerm } = this.state;
		const searchResponse = debounce(this.onSearchChange, 2000);

		const options = {
			//filter: this.props.filter,
			responsive: 'stacked',
			serverSide: true,
			count: paging.total_count,
			page: paging.page,
			onTableChange: this.onTableChange,
			print: false,
			download: false,
			onFilterChange: this.onFilterChange,
			onColumnSortChange: this.onSortChange,
			viewColumns: false,
			search: true,
			searchText: searchTerm,
			onSearchChange: searchResponse,
			onSearchClose: this.onSearchClose,
			filter: true,
			selectableRows: 'none'
			// isRowSelectable:()=>{return false}
		};

		const { columns, title } = this.props;
		return (
			<Fragment>
				<Grid container>
					<Grid item xs={12}>
						<MUIDataTable
							title={
								<Typography variant='subtitle1'>
									{title && title}
									{loading && (
										<CircularProgress
											size={24}
											style={{ marginLeft: 15, position: 'relative', top: 4 }}
										/>
									)}
								</Typography>
							}
							data={data}
							columns={columns}
							options={options}
						/>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

TableList.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.array.isRequired,
	filter: PropTypes.bool,
	filterList: PropTypes.node,
	apiParams: PropTypes.shape({
		endpoint: PropTypes.string,
		params: PropTypes.object
	}).isRequired,
	onFilterChange: PropTypes.func
};
TableList.defaultProps = {
	filter: false
};
export default TableList;
