import React from 'react';
import MaterialTable from 'material-table';
import apiService from '../../../lib/apiService';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { API_ENDPOINT_CATEGORIES } from '../../../lib/apiEndpoints';
import { openModal } from '../../../redux/actions/modalAction';
import ModalParams from '../../../models/modal';
import { connect } from 'react-redux';

class CategoriesListTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true
    };
  }
  componentDidMount() {
    this.fetchCategories();
  }

  onCategoryDeleteClicked = id => {
    let deleteModal = new ModalParams();
    deleteModal = {
      type: 'confirmation',
      confirmationText: 'Are you sure, you want to delete selected Category ?',
      size: 'medium',
      showCloseBtn: true,
      onConfirm: () => {
        apiService
          .request('Delete', API_ENDPOINT_CATEGORIES + '/' + id)
          .then(() => {
            // this.forceUpdate();
            this.setState({
              categories: [
                ...this.state.categories.filter(category => category._id !== id)
              ]
            });
          });
      }
    };
    this.props.openModal(deleteModal);

    // apiService
    //   .request('Delete', API_ENDPOINT_CATEGORIES + '/' + id)
    //   .then(() => {
    //     // this.forceUpdate();
    //     this.setState({
    //       categories: [
    //         ...this.state.categories.filter(category => category._id !== id)
    //       ]
    //     });
    //   });
  };
  fetchCategories() {
    apiService
      .request('GET', 'admin/categories')
      .then(resp => {
        this.setState({ categories: [...resp.data] });
      })
      .finally(() => this.toggleLoading(false));
  }
  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };
  // const [loading, toggleLoading] = useState(true);
  // //useEffect(() => {
  // toggleLoading(true);

  //});

  render() {
    const { categories, loading } = this.state;
    const columns = [
      { title: 'Name', field: 'name' },
      { title: 'Slug', field: 'slug' },
      {
        title: 'Actions',
        render: rowData => (
          <>
            <IconButton
              onClick={() =>
                this.props.history.push(`/categories/${rowData._id}`)
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => this.onCategoryDeleteClicked(rowData._id)}
            >
              <Delete />
            </IconButton>
          </>
        )
      }
    ];

    return (
      <MaterialTable
        isLoading={loading}
        title='Item Categories'
        data={categories}
        columns={columns}
        parentChildData={(row, rows) =>
          rows.find(category => category._id === row.parent)
        }
        options={{
          selection: true
          //actionsColumnIndex: -1
        }}
      />
    );
  }
}

// export default withRouter(CategoriesListTable);

export default withRouter(
  connect(
    null,
    { openModal }
  )(CategoriesListTable)
);
