import React from 'react';
import apiService from '../../../../lib/apiService';
import { swapUpdateEndpoint } from '../../../../lib/apiEndpoints';

const DelayedForm = ({ swap, reloadSwap, closeModal }) => {
 
  const reason = React.createRef();
  const submitForm = () => {
    apiService
      .request('PUT', swapUpdateEndpoint(swap._id, 'delayed'), {
        message: reason.current.value,
        
      })
      .then(resp => {
        if (reloadSwap) {
          reloadSwap();
        }
        if (closeModal) {
          closeModal();
        }
      });
  };
  const calculateDefaultAmount = () => {
    let current_offer = swap.offers.find(offer => offer._id === swap.current_offer_id);
    return current_offer['itemization']['shipping'];
  };
  return (
    <>
      <div className='form-group'>
        {/* <label>Enter shipping amount to reimburse to seller</label> */}
        {/* <div className='input-group'>
          <div className='input-group-prepend'>
            <div className='input-group-text'>USD</div>
          </div>
          <input
            type='tel'
            placeholder='Reimbursement Amount'
            defaultValue={calculateDefaultAmount()}
            className='form-control col-4'
            ref={reimbursementAmountRef}
          />
        </div> */}
      </div>
      <div className='form-group'>
        <label>Delayed Reason</label>
        <input
          type='text'
          className='form-control'
          ref={reason}
          placeholder='Enter Delayed Reason'
        />
      </div>
      <button type='button' onClick={submitForm} className='btn btn-primary'>
        Submit
      </button>
    </>
  );
};

export default DelayedForm;
